import React from "react";
import { Link } from "react-router-dom";

import SEO from "../Seo";
import { GeneralEmail } from "../EmailForm";
import { PreFooter } from "../Footer";

import * as ROUTES from "../../constants/routes";

import ImgHeroIllustration from "../../assets/Hero-Illustration.svg";
import ImgUpcomingGraduates from "../../assets/Upcoming-graduates-illustration.svg";
import ImgArrowTertiary from "../../assets/Arrow-Tertiary.svg";
import ImgNewPro from "../../assets/New-pro-illustration.svg";
import ImgCompany from "../../assets/Companies_1.svg";
import ImgOaknut from "../../assets/Oaknut.svg";
import ImgOaktree from "../../assets/Oaktree.svg";
import ImgOakwood from "../../assets/Oakwood.svg";
import ImgOaknutIllustration from "../../assets/Oaknut---Illustration.svg";
import ImgOaktreeIllustration from "../../assets/OakTree-Illustration.svg";
import ImgOakwoodIllustration from "../../assets/Oakwood-Illustration.svg";
import ImgIllustrationI from "../../assets/Illustration-I.svg";
import ImgIllustrationII from "../../assets/Illustration-II.svg";
import ImgIllustrationIII from "../../assets/Illustration-III.svg";

const Landing = () => (
  <>
    <SEO title="Oaks - Your all around career capital partner" />
    <div className="section-hero">
      <div className="wrapper w-clearfix">
        <div className="block-heroimage responsive-image">
          <img src={ImgHeroIllustration} alt="" />
        </div>
        <div className="block-herotext">
          <div className="h1">Raise talents through our knowledge inclusive society</div>
          <div className="bodytext">
            We strive to open access all knowledge so everyone can have similar opportunity for advancement and impact.
            We, Oaks co. are the first all-around career capital firm in Indonesia
          </div>
          <div className="block-button">
            <a href="#Programs" className="button-primary w-inline-block">
              <div>Explore programs</div>
            </a>
            <a
              href="https://wa.me/6281221221500?text=Hi%2C%20aku%20mau%20tanya%2F%20request%2F%20book%20call%20tentang...."
              target="_blank"
              rel="noopener noreferrer"
              className="button-primary button-tertiary w-inline-block"
            >
              <div className="badge-medium">
                <div>Free</div>
              </div>
              <div>Book a call</div>
            </a>
          </div>
        </div>
        <div className="block-heroimage">
          <img src={ImgHeroIllustration} alt="" />
        </div>
      </div>
    </div>
    <div className="section-personas">
      <div className="wrapper">
        <div className="h2 h2-section-personas">Why are we doing this?</div>
        <div className="bodytext bodytext-section-personas">
          We believe, knowledge has the power to transform economies and
          <br />
          societies. It can control the access for advancement and opportunity.
        </div>
        <div className="block-three-row">
          <img src={ImgUpcomingGraduates} alt="" className="user-personas-student" />
          <div className="h4">Students and New Graduates</div>
          <Link to={ROUTES.OAKNUT} className="hyperlink-tertiary w-inline-block">
            <div className="hyperlink-text">Learn more</div>
            <img src={ImgArrowTertiary} alt="" className="arrow-icon" />
          </Link>
        </div>
        <div className="block-three-row">
          <img src={ImgNewPro} alt="" className="user-personas-employee" />
          <div className="h4">Young Professionals</div>
          <Link to={ROUTES.OAKTREE} className="hyperlink-tertiary w-inline-block">
            <div className="hyperlink-text">Learn more</div>
            <img src={ImgArrowTertiary} alt="" className="arrow-icon" />
          </Link>
        </div>
        <div className="block-three-row">
          <img src={ImgCompany} alt="" className="user-personas-company" />
          <div className="h4">Company and University</div>
          <Link to={ROUTES.OAKWOOD} className="hyperlink-tertiary w-inline-block">
            <div className="hyperlink-text">Learn more</div>
            <img src={ImgArrowTertiary} alt="" className="arrow-icon" />
          </Link>
        </div>
      </div>
    </div>
    <div id="Programs" className="section-zebra-a">
      <div className="wrapper w-clearfix">
        <div className="block-zebra-a-image">
          <img src={ImgOaknutIllustration} alt="" />
        </div>
        <div className="block-zebra-a-text zebra-a-text-oaknut">
          <img src={ImgOaknut} alt="" className="logo-product-zebra" />
          <div className="h3 h3-zebra">Complete, link, and match upcoming graduates to industry&#x27;s needs</div>
          <div className="bodytext">
            {
              "We equip upcoming graduates with business intelligences and link them to industries in need. All these for "
            }
            <strong className="inline-bold">FREE</strong>
          </div>
          <div className="block-button-zebra">
            <Link to={ROUTES.OAKNUT} className="button-primary w-inline-block">
              <div>Apply as student</div>
            </Link>
            <a href={`${ROUTES.OAKNUT}#oaknut-career-syllabus`} className="hyperlink-tertiary w-inline-block">
              <div className="hyperlink-text">Learn more</div>
              <img src={ImgArrowTertiary} alt="" className="arrow-icon" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="section-zebra-b">
      <div className="wrapper w-clearfix">
        <div className="block-zebra-b-image responsive-image">
          <img src={ImgOaktreeIllustration} alt="" />
        </div>
        <div className="block-zebra-b-text">
          <img src={ImgOaktree} alt="" className="logo-product-zebra" />
          <div className="h3 h3-zebra">No need dozens of millions to be in the driver seat of your career</div>
          <div className="bodytext">
            We shape young professionals up for success and impact at your every job, every level, in any business,
            whether it is through career coaching, love counseling, or professional development programs.
          </div>
          <Link to={ROUTES.CAREER_CAPITAL} className="hyperlink-tertiary hyperlink-tertiary-fullblock w-inline-block">
            <div className="hyperlink-text">Career Coaching</div>
            <img src={ImgArrowTertiary} alt="" className="arrow-icon" />
          </Link>
          <a
            href={`${ROUTES.SCHOOL_FOR_PROFESSIONAL}#oaktree-courses`}
            className="hyperlink-tertiary hyperlink-tertiary-fullblock w-inline-block"
          >
            <div className="hyperlink-text">Professional Development Programs</div>
            <img src={ImgArrowTertiary} alt="" className="arrow-icon" />
          </a>
          <div className="block-button-zebra">
            <Link to={ROUTES.OAKTREE} className="button-primary w-inline-block">
              <div>Explore Us</div>
            </Link>
          </div>
        </div>
        <div className="block-zebra-b-image">
          <img src={ImgOaktreeIllustration} alt="" />
        </div>
      </div>
    </div>
    <div className="section-zebra-a">
      <div className="wrapper w-clearfix">
        <div className="block-zebra-a-image">
          <img src={ImgOakwoodIllustration} alt="" />
        </div>
        <div className="block-zebra-a-text">
          <img src={ImgOakwood} alt="" className="logo-product-zebra" />
          <div className="h3 h3-zebra">
            Align all of your HR systems in less than 3 months and less than an HR head salary
          </div>
          <div className="bodytext">
            We help companies to design an aligned people architecture by employing insights from heuristics, cognitive,
            and mechanism design to allow natural retention and development of its talents, especially millennial group.
          </div>
          <a
            href={`${ROUTES.HR_STRATEGY}#partner-coach`}
            className="hyperlink-tertiary hyperlink-tertiary-oakwood w-inline-block"
          >
            <div className="hyperlink-text">HR Architecture Design</div>
            <img src={ImgArrowTertiary} alt="" className="arrow-icon" />
          </a>
          <a
            href={`${ROUTES.HR_STRATEGY}?tab=hr-coaching#partner-coach`}
            className="hyperlink-tertiary hyperlink-tertiary-oakwood w-inline-block"
          >
            <div className="hyperlink-text">HR Strategic Coaching</div>
            <img src={ImgArrowTertiary} alt="" className="arrow-icon" />
          </a>
          <Link
            to={ROUTES.SCHOOL_FOR_PROFESSIONAL}
            className="hyperlink-tertiary hyperlink-tertiary-oakwood w-inline-block"
          >
            <div className="hyperlink-text">Professional Development Programs</div>
            <img src={ImgArrowTertiary} alt="" className="arrow-icon" />
          </Link>
          <Link
            to={ROUTES.TALENT_SOLUTIONS}
            className="hyperlink-tertiary hyperlink-tertiary-oakwood-child w-inline-block"
          >
            <div className="hyperlink-text">Talent Solutions</div>
            <img src={ImgArrowTertiary} alt="" className="arrow-icon" />
          </Link>
          <div className="block-button-zebra block-button-zebra-oakwood">
            <Link to={ROUTES.OAKWOOD} className="button-primary button-tertiary w-inline-block">
              <div className="badge-medium badge-medium-oakwood">
                <div>Free</div>
              </div>
              <div>Explore Our Services</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
    <div className="section-how-are-we-doing-this">
      <div className="wrapper w-clearfix">
        <div className="h2 h2-how-are-we-doing-this">How are we doing this?</div>
        <div className="bodytext">
          We understand, that trust is earned. We ensure that we design our
          <br />
          solutions and teams to gain your trust quickly based on following factors.
        </div>
        <div className="block-three-row block-three-row-how-doing-this">
          <img src={ImgIllustrationI} alt="" className="user-personas-student" />
          <div className="h4 h4-user-personas-text">Cost leadership</div>
          <div className="bodytext">
            We creatively manage our cost drivers to provide you the optimum ROI. So we can offer you 80-90% lower price
            than the market.
          </div>
        </div>
        <div className="block-three-row block-three-row-how-doing-this">
          <img src={ImgIllustrationII} alt="" className="user-personas-employee" />
          <div className="h4 h4-user-personas-text">Network of great credentials</div>
          <div className="bodytext">
            Our team consists of ex-consultants, teachers, multinational experts in their fields, best US and UK
            universities certificate holders
          </div>
        </div>
        <div className="block-three-row block-three-row-how-doing-this">
          <img src={ImgIllustrationIII} alt="" className="user-personas-company" />
          <div className="h4 h4-user-personas-text">Expanded reach</div>
          <div className="bodytext">
            We are here to support everyone&#x27;s growth, including rural university graduates, lower-mid income
            professionals, and new startups
          </div>
        </div>
      </div>
    </div>
    <GeneralEmail />
    <PreFooter />
  </>
);

export default Landing;
