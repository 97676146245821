import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";

import SEO from "../Seo";
import { EnrollmentEmail } from "../EmailForm";

const COURSES_API_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}/api/post`;
const IMAGE_API_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}/asset`;

const Courses = ({ match }) => {
  const [course, setCourse] = useState(null);
  const { id } = match.params;

  useEffect(() => {
    axios
      .get(`${COURSES_API_ENDPOINT}/${id}`)
      .then(res => {
        const postData = res.data.data;
        const postMeta = {};
        postData.post_meta.forEach(meta => {
          postMeta[meta.meta_key] = meta.meta_value;
        });
        postData.meta = postMeta;
        setCourse(postData);
      })
      .catch(err => {
        console.log(err);
      });
  }, [id]);

  return (
    <>
      {course ? (
        <>
          <SEO
            title={course.post_title}
            description={course.post_excerpt}
            image={course.meta.cover_image ? `${IMAGE_API_ENDPOINT}/${course.meta.cover_image}` : ""}
          />
          <div className="section-course-overview">
            <div className="wrapper w-clearfix">
              <div className="block-course-content">
                <div className="h2 h2-course-title">{course.post_title}</div>
                <div className="bodytext bodytext-course-description">
                  {course.post_excerpt}
                  <br />
                </div>
                <div className="block-course-mentors">
                  <div className="text-course-mentors-name">
                    Dea Gendyna
                    <br />
                  </div>
                  <div className="bodytext">Managing Partner of Oaks Co.</div>
                </div>
                <div className="block-course-cta">
                  <a href="#enroll-course" className="button-primary w-inline-block">
                    <div>Enroll now</div>
                  </a>
                </div>
                <div className="block-course-overview-description">
                  <div className="h2 h2-course-content-title">Overview</div>
                  <div className="bodytext bodytext-course-description">{course.post_content}</div>
                  <div className="h2 h2-course-content-title">Program Design</div>
                  <div className="bodytext bodytext-course-description">
                    {course.meta.programDesign ? course.meta.programDesign : null}
                    <br />
                  </div>
                  <div className="h2 h2-course-content-title">Certification</div>
                  <div className="bodytext bodytext-course-description">
                    {course.meta.certification ? course.meta.certification : null}
                    <br />
                  </div>
                  <div className="h2 h2-course-content-title">Program Content</div>
                  <div className="bodytext bodytext-course-description">
                    {course.meta.programContent ? course.meta.programContent : null}
                    <br />
                  </div>
                  <div className="h2 h2-course-content-title">Participant Profile</div>
                  <div className="bodytext bodytext-course-description">
                    {course.meta.participantRole ? course.meta.participantRole : null}
                    <br />
                  </div>
                  <div className="h2 h2-course-content-title">Mentor Profile</div>
                  <div className="bodytext bodytext-course-description">
                    {course.meta.mentor ? course.meta.mentor : null}
                    <br />
                  </div>
                  <div className="h2 h2-course-content-title">Investment</div>
                  <div className="bodytext bodytext-course-description">
                    {course.meta.investment ? course.meta.investment : null}
                    <br />
                  </div>
                  <div className="h2 h2-course-content-title">Starting dates and duration</div>
                  <div className="bodytext bodytext-course-description">
                    {course.meta.startingDatesAndDuration ? course.meta.startingDatesAndDuration : null}
                    <br />
                  </div>
                  <div className="h2 h2-course-content-title">Type and location</div>
                  <div className="bodytext bodytext-course-description">
                    {course.meta.typeAndLocation ? course.meta.typeAndLocation : null}
                    <br />
                  </div>
                  <div className="h2 h2-course-content-title">Status</div>
                  <div className="bodytext bodytext-course-description">
                    {course.meta.status ? course.meta.status : null}
                    <br />
                  </div>
                </div>
              </div>
              <div className="block-course-content-card">
                {course.meta.cover_image ? (
                  <div className="block-course-card-images">
                    <img src={`${IMAGE_API_ENDPOINT}/${course.meta.cover_image}`} alt="" />
                  </div>
                ) : null}
                <div className="block-course-card-details">
                  <div className="bodytext course-card-title">Course name</div>
                  <div className="text-course-mentors-name">
                    {course.post_title}
                    <br />
                  </div>
                  <div className="bodytext course-card-title">Starting dates</div>
                  <div className="text-course-mentors-name">
                    {course.meta.startingDates ? course.meta.startingDates : null}
                    <br />
                  </div>
                  <div className="bodytext course-card-title">Type and location</div>
                  <div className="text-course-mentors-name">
                    {course.meta.typeAndLocation ? course.meta.typeAndLocation : null}
                    <br />
                  </div>
                  <div className="block-primary-button-course">
                    <a href="#enroll-course" className="button-primary button-primary-full w-inline-block">
                      <div>Enroll now</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      <EnrollmentEmail />
    </>
  );
};

Courses.propTypes = { match: PropTypes.instanceOf(Object).isRequired };

export default Courses;
