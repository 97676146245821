import React, { useState } from "react";
import axios from "axios";

import ImgWhatsapp from "../../assets/icon-whatsapp.svg";
import ImgExample from "../../assets/Example.png";

const GeneralEmail = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [yourRole, setYourRole] = useState("");
  const [organization, setOrganization] = useState("");
  const [subject, setSubject] = useState("I want to know about");
  const [message, setMessage] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [btnText, setBtnText] = useState("Submit");
  const [failStyle, setFailStyle] = useState({ display: "none" });
  const [doneStyle, setDoneStyle] = useState({ display: "none", marginTop: 10 });

  const clearForm = () => {
    setFullName("");
    setEmail("");
    setPhone("");
    setYourRole("");
    setOrganization("");
    setSubject("I want to know about");
    setMessage("");
    setBtnDisabled(false);
    setBtnText("Submit");
  };

  const onSubmit = event => {
    setFailStyle({ display: "none" });
    setDoneStyle({ display: "none", marginTop: 10 });
    setBtnText("Please wait....");
    setBtnDisabled(true);

    const MailAPI = `${process.env.REACT_APP_API_ENDPOINT}/api/inbox`;
    const axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const formData = new FormData();
    formData.append("Full-name", fullName);
    formData.append("Email-address", email);
    formData.append("Phone-number", phone);
    formData.append("Your-role", yourRole);
    formData.append("Organization", organization);
    formData.append("Subject", subject);
    formData.append("Message", message);
    formData.append("Mail-From", process.env.REACT_APP_WEBSITE_URL);
    formData.append("Mail-Reply", email);
    formData.append("Mail-Form", "General Form");
    formData.append("Mail-Category", "general-contact-form");
    formData.append("Mail-Subject", "General Form Submitted");
    formData.append("Mail-Title", `${fullName} - ${organization} [${yourRole}]`);
    formData.append("Mail-Excerpt", subject);
    formData.append("Mail-Content", message);

    axios
      .post(MailAPI, formData, axiosConfig)
      .then(res => {
        clearForm();
        if (res.data.code === 200) {
          setDoneStyle({ display: "block", marginTop: 10 });
        } else {
          setFailStyle({ display: "block" });
        }
      })
      .catch(err => {
        setFailStyle({ display: "block" });
        console.log(err);
      });

    event.preventDefault();
  };

  return (
    <div id="general-contact" className="section-general-contact">
      <div className="wrapper w-clearfix">
        <div className="block-general-contact">
          <div className="w-form">
            <form id="email-form" name="email-form" data-name="Email Form" action="submitform" onSubmit={onSubmit}>
              <label htmlFor="fullName" className="form-label">
                Full name
              </label>
              <input
                type="text"
                className="form-text-field w-input"
                maxLength="256"
                name="fullName"
                placeholder="Type your full name"
                id="fullName"
                value={fullName}
                onChange={event => setFullName(event.target.value)}
                required
              />
              <div className="block-form-two-row">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-text-field w-input"
                  maxLength="256"
                  name="email"
                  placeholder="Type your email"
                  id="email"
                  value={email}
                  onChange={event => setEmail(event.target.value)}
                  required
                />
              </div>
              <div className="block-form-two-row block-form-two-row-child">
                <label htmlFor="phone" className="form-label">
                  Phone
                </label>
                <input
                  type="text"
                  className="form-text-field w-input"
                  maxLength="256"
                  name="phone"
                  placeholder="Valid phone number"
                  id="phone"
                  value={phone}
                  onChange={event => setPhone(event.target.value)}
                  required
                />
              </div>
              <div className="block-form-two-row">
                <label htmlFor="yourRole" className="form-label">
                  Your role
                </label>
                <select
                  id="yourRole"
                  name="yourRole"
                  className="form-text-field w-select"
                  value={yourRole}
                  onChange={event => setYourRole(event.target.value)}
                  required
                >
                  <option value="">Select one</option>
                  <option value="An Upcoming Graduate or a Freshgraduate">
                    An Upcoming Graduate or a Freshgraduate
                  </option>
                  <option value="A Professional">A Professional</option>
                  <option value="A Company (representative)">A Company (representative)</option>
                  <option value="A University (representative)">A University (representative)</option>
                </select>
              </div>
              <div className="block-form-two-row block-form-two-row-child">
                <label htmlFor="organization" className="form-label">
                  Organization name
                </label>
                <input
                  type="text"
                  className="form-text-field w-input"
                  maxLength="256"
                  name="organization"
                  placeholder="Type your organization name"
                  id="organization"
                  value={organization}
                  onChange={event => setOrganization(event.target.value)}
                  required
                />
              </div>
              <label htmlFor="subject" className="form-label">
                Subject
              </label>
              <select
                id="subject"
                name="subject"
                className="form-text-field w-select"
                value={subject}
                onChange={event => setSubject(event.target.value)}
              >
                <option value="I want to know about">I want to know about</option>
                <option value="Help me fix an issue">Help me fix an issue</option>
              </select>
              <label htmlFor="message" className="form-label">
                Message
              </label>
              <textarea
                placeholder="Tell us how can we might help you"
                maxLength="5000"
                id="message"
                name="message"
                className="form-text-area w-input"
                value={message}
                onChange={event => setMessage(event.target.value)}
                required
              />
              <input type="submit" value={btnText} className="button-primary w-button" disabled={btnDisabled} />
            </form>
            <div className="w-form-done" style={doneStyle}>
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div className="w-form-fail" style={failStyle}>
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </div>
        <div className="block-general-contact-description">
          <div className="h2 h2-block-general-contact-description">
            We are here to help anything what you need to ask.
          </div>
          <div className="bodytext">
            Setting up both talents and business for success and impact, we are the first all-around career capital firm
            in Indonesia.
          </div>
          <div className="whatsapp-link-title">Chat in WhatsApp</div>
          <a
            href="https://wa.me/6281221221500?text=Hi%2C%20aku%20mau%20tanya%2F%20request%2F%20book%20call%20tentang...."
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link whatsapp-link w-inline-block"
          >
            <img src={ImgWhatsapp} alt="" className="ico-whatsapp" />
            <div className="nav-link-text">
              <strong>+6281221221500</strong>
            </div>
          </a>
          <div className="bodytext testimonial-text">
            <em>
              “We may have hired several people from Ivy League, but they don’t always give us performance. We really
              don’t have enough qualified talents in the market.”
            </em>
          </div>
          <div className="testimonial-avatar-placeholder">
            <img src={ImgExample} width="32" alt="" className="testimonial-avatar" />
            <div className="testimonial-position">Someone in a Head position of a startup</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralEmail;
