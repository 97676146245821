import React from "react";
import { Link } from "react-router-dom";

import * as ROUTES from "../../constants/routes";

import ImgLogo from "../../assets/logo.svg";
import ImgOaknut from "../../assets/Oaknut.svg";
import ImgOaktree from "../../assets/Oaktree.svg";
import ImgOakwood from "../../assets/Oakwood.svg";
import ImgUpcoming from "../../assets/Upcoming.svg";
import ImgYoung from "../../assets/Young.svg";
import ImgCompanies from "../../assets/Companies.svg";
import ImgWhatsapp from "../../assets/icon-whatsapp.svg";

import "./navigation.css";

function Navigation() {
  return (
    <>
      <div className="section-nav section-nav-mobile">
        <div
          data-collapse="medium"
          data-animation="default"
          data-duration="400"
          data-doc-height="1"
          className="block-nav w-nav"
        >
          <div className="wrapper">
            <Link to={ROUTES.LANDING} className="logo w-nav-brand w--current">
              <img src={ImgLogo} alt="" />
            </Link>
            <nav role="navigation" className="nav-menu w-nav-menu">
              <Link to={ROUTES.LANDING} className="nav-link w-nav-link w--current">
                Home
              </Link>
              <div data-delay="0" className="w-dropdown">
                <div className="nav-dropdown w-dropdown-toggle">
                  <div className="w-icon-dropdown-toggle" />
                  <div>Client services</div>
                </div>
                <nav className="nav-dropdown-list w-dropdown-list">
                  <Link to={ROUTES.OAKNUT} className="w-dropdown-link">
                    <img src={ImgOaknut} alt="" className="logo-oaknut" />
                  </Link>
                  <Link to={ROUTES.SYLLABUS} className="w-dropdown-link">
                    Incubation program
                  </Link>
                  <a href={`${ROUTES.OAKNUT}#oaknut-suggest-city`} className="w-dropdown-link">
                    Suggest your campus
                  </a>
                  <Link
                    to={ROUTES.COURSES.replace(":id", 32).replace(
                      ":slug",
                      "upcoming-and-new-graduates-job-application-preparation-guide"
                    )}
                    className="w-dropdown-link"
                  >
                    Job Application
                    <br />
                    Preparation Class
                  </Link>
                  <Link to={ROUTES.OAKTREE} className="w-dropdown-link">
                    <img src={ImgOaktree} alt="" className="logo-oaktree" />
                  </Link>
                  <Link to={ROUTES.SCHOOL_FOR_PROFESSIONAL} className="w-dropdown-link">
                    School for professional
                  </Link>
                  <a href={`${ROUTES.CAREER_CAPITAL}#oaktree-apply-mentor`} className="w-dropdown-link">
                    Find a career mentor
                  </a>
                  <Link to={ROUTES.OAKWOOD} className="w-dropdown-link">
                    <img src={ImgOakwood} alt="" className="logo-oakwood" />
                  </Link>
                  <Link to={ROUTES.HR_STRATEGY} className="w-dropdown-link">
                    HR Strategic Coach and Partner
                  </Link>
                  <a href={`${ROUTES.ABOUT}#general-contact`} className="w-dropdown-link">
                    Get free trainees from us
                  </a>
                  <a href={`${ROUTES.ABOUT}#general-contact`} className="nav-dropdown-cta w-dropdown-link">
                    Book a call
                  </a>
                </nav>
              </div>
              <div data-delay="0" className="w-dropdown">
                <div className="nav-dropdown w-dropdown-toggle">
                  <div className="w-icon-dropdown-toggle" />
                  <div>Program and courses</div>
                </div>
                <nav className="nav-dropdown-list w-dropdown-list">
                  <a href={`${ROUTES.HR_STRATEGY}#partner-coach`} className="w-dropdown-link">
                    Career coaching
                  </a>
                  <a href={`${ROUTES.SCHOOL_FOR_PROFESSIONAL}#oaktree-courses`} className="w-dropdown-link">
                    Courses and Specialization
                  </a>
                  <a href={`${ROUTES.HR_STRATEGY}#partner-coach`} className="w-dropdown-link">
                    Corporate Programs
                  </a>
                  <a href={`${ROUTES.HR_STRATEGY}#partner-coach`} className="w-dropdown-link">
                    Executive Coaching
                  </a>
                </nav>
              </div>
              <div data-delay="0" className="w-dropdown">
                <div className="nav-dropdown w-dropdown-toggle">
                  <div className="w-icon-dropdown-toggle" />
                  <div>Pro-bono supports</div>
                </div>
                <nav className="nav-dropdown-list w-dropdown-list">
                  <img src={ImgUpcoming} alt="" className="logo-upcoming" />
                  <Link to={ROUTES.SYLLABUS} className="w-dropdown-link">
                    Incubation program
                  </Link>
                  <img src={ImgYoung} alt="" className="logo-oaktree" />
                  <a href={`${ROUTES.CAREER_CAPITAL}#oaktree-apply-mentor`} className="w-dropdown-link">
                    Find a work and life discussion buddy
                  </a>
                  <a href={`${ROUTES.CAREER_CAPITAL}#oaktree-apply-mentor`} className="w-dropdown-link">
                    Get a mentor to help guide your career
                  </a>
                  <a href={`${ROUTES.CAREER_CAPITAL}#oaktree-apply-mentor`} className="w-dropdown-link">
                    Get a thought-leader to speak in your events
                  </a>
                  <img src={ImgCompanies} alt="" className="logo-oakwood" />
                  <Link to={ROUTES.HR_STRATEGY} className="w-dropdown-link">
                    Get free qualified trainees for your company
                  </Link>
                  <a href={`${ROUTES.ABOUT}#general-contact`} className="nav-dropdown-cta w-dropdown-link">
                    Book a free initial HR consultation
                  </a>
                </nav>
              </div>
              <Link to={ROUTES.ABOUT} className="nav-link w-nav-link">
                About
              </Link>
              <a
                href="https://wa.me/6281221221500?text=Hi%2C%20aku%20mau%20tanya%2F%20request%2F%20book%20call%20tentang...."
                target="_blank"
                rel="noopener noreferrer"
                className="nav-link w-inline-block"
              >
                <img src={ImgWhatsapp} alt="" className="ico-whatsapp" />
                <div className="nav-link-text">
                  <strong>+6281221221500</strong>
                </div>
              </a>
            </nav>
            <div className="w-nav-button">
              <div className="w-icon-nav-menu" />
            </div>
          </div>
        </div>
      </div>
      <div className="section-nav section-nav-desktop">
        <div
          data-collapse="medium"
          data-animation="default"
          data-duration="400"
          data-doc-height="1"
          className="block-nav w-nav"
        >
          <div className="wrapper">
            <Link to={ROUTES.LANDING} className="logo w-nav-brand w--current">
              <img src={ImgLogo} alt="" />
            </Link>
            <nav role="navigation" className="w-nav-menu">
              <Link to={ROUTES.LANDING} className="nav-link w-nav-link w--current">
                Home
              </Link>
              <div data-hover="1" data-delay="0" className="w-dropdown">
                <div className="nav-dropdown w-dropdown-toggle">
                  <div className="w-icon-dropdown-toggle" />
                  <div>Client services</div>
                </div>
                <nav className="nav-dropdown-list w-dropdown-list">
                  <Link to={ROUTES.OAKNUT} className="w-dropdown-link">
                    <img src={ImgOaknut} alt="" className="logo-oaknut" />
                  </Link>
                  <Link to={ROUTES.SYLLABUS} className="w-dropdown-link">
                    Incubation program
                  </Link>
                  <a href={`${ROUTES.OAKNUT}#oaknut-suggest-city`} className="w-dropdown-link">
                    Suggest your campus
                  </a>
                  <Link
                    to={ROUTES.COURSES.replace(":id", 32).replace(
                      ":slug",
                      "upcoming-and-new-graduates-job-application-preparation-guide"
                    )}
                    className="w-dropdown-link"
                  >
                    Job Application
                    <br />
                    Preparation Class
                  </Link>
                  <Link to={ROUTES.OAKTREE} className="w-dropdown-link">
                    <img src={ImgOaktree} alt="" className="logo-oaktree" />
                  </Link>
                  <Link to={ROUTES.SCHOOL_FOR_PROFESSIONAL} className="w-dropdown-link">
                    School for Professional
                  </Link>
                  <a href={`${ROUTES.CAREER_CAPITAL}#oaktree-apply-mentor`} className="w-dropdown-link">
                    Find a career mentor
                  </a>
                  <Link to={ROUTES.OAKWOOD} className="w-dropdown-link">
                    <img src={ImgOakwood} alt="" className="logo-oakwood" />
                  </Link>
                  <Link to={ROUTES.HR_STRATEGY} className="w-dropdown-link">
                    HR Strategic Coach and Partner
                  </Link>
                  <Link to={ROUTES.TALENT_SOLUTIONS} className="w-dropdown-link">
                    Talent Solution
                  </Link>
                  <a
                    href="https://wa.me/6281221221500?text=Hi%2C%20aku%20mau%20tanya%2F%20request%2F%20book%20call%20tentang...."
                    target="_blank"
                    rel="noopener noreferrer"
                    className="nav-dropdown-cta w-dropdown-link"
                  >
                    Book a call
                  </a>
                </nav>
              </div>
              <div data-hover="1" data-delay="0" className="w-dropdown">
                <div className="nav-dropdown w-dropdown-toggle">
                  <div className="w-icon-dropdown-toggle" />
                  <div>Program and courses</div>
                </div>
                <nav className="nav-dropdown-list w-dropdown-list">
                  <a href={`${ROUTES.HR_STRATEGY}#partner-coach`} className="w-dropdown-link">
                    Career Coaching
                  </a>
                  <a href={`${ROUTES.SCHOOL_FOR_PROFESSIONAL}#oaktree-courses`} className="w-dropdown-link">
                    Courses and Specialization
                  </a>
                  <a href={`${ROUTES.HR_STRATEGY}#partner-coach`} className="w-dropdown-link">
                    Corporate Programs
                  </a>
                  <a href={`${ROUTES.HR_STRATEGY}#partner-coach`} className="w-dropdown-link">
                    Executive Coaching
                  </a>
                </nav>
              </div>
              <div data-hover="1" data-delay="0" className="w-dropdown">
                <div className="nav-dropdown w-dropdown-toggle">
                  <div className="w-icon-dropdown-toggle" />
                  <div>Pro-bono supports</div>
                </div>
                <nav className="nav-dropdown-list w-dropdown-list">
                  <img src={ImgUpcoming} alt="" className="logo-upcoming" />
                  <Link to={ROUTES.SYLLABUS} className="w-dropdown-link">
                    Incubation program
                  </Link>
                  <img src={ImgYoung} alt="" className="logo-oaktree" />
                  <a href={`${ROUTES.CAREER_CAPITAL}?tab=be-mentor#oaktree-apply-mentor`} className="w-dropdown-link">
                    Be a mentor or a speaker
                  </a>
                  <a href={`${ROUTES.CAREER_CAPITAL}#oaktree-apply-mentor`} className="w-dropdown-link">
                    Get a mentor to help guide your career
                  </a>
                  <a href={`${ROUTES.CAREER_CAPITAL}#oaktree-apply-mentor`} className="w-dropdown-link">
                    Get a thought-leader to speak in your events
                  </a>
                  <img src={ImgCompanies} alt="" className="logo-oakwood" />
                  <Link to={ROUTES.HR_STRATEGY} className="w-dropdown-link">
                    Get free qualified trainees for your company
                  </Link>
                  <a href={`${ROUTES.ABOUT}#general-contact`} className="nav-dropdown-cta w-dropdown-link">
                    Book a free initial HR consultation
                  </a>
                </nav>
              </div>
              <Link to={ROUTES.ABOUT} className="nav-link w-nav-link">
                About
              </Link>
              <a
                href="https://wa.me/6281221221500?text=Hi%2C%20aku%20mau%20tanya%2F%20request%2F%20book%20call%20tentang...."
                target="_blank"
                rel="noopener noreferrer"
                className="nav-link w-inline-block"
              >
                <img src={ImgWhatsapp} alt="" className="ico-whatsapp" />
                <div className="nav-link-text">
                  <strong>+6281221221500</strong>
                </div>
              </a>
            </nav>
            <div className="w-nav-button">
              <div className="w-icon-nav-menu" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navigation;
