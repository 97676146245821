import React from "react";

import * as ROUTES from "../../constants/routes";

import ImgArrowWhite from "../../assets/Arrow-White.svg";
import ImgPreFooterIllustration from "../../assets/Pre-footer-illustrations.svg";

function PreFooter() {
  return (
    <div className="section-prefooter">
      <div className="wrapper">
        <div className="block-prefooter w-clearfix">
          <div className="block-prefooter-text">
            <div className="h2 h2-section-prefooter">
              <strong className="bold-text">Let us help you grow</strong>
            </div>
            <div className="bodytext bodytext-section-prefooter">
              Oaks Co. provides all around support to set up successful win-win relationship between business and
              professionals.
            </div>
            <div className="block-button-zebra">
              <a href={`${ROUTES.ABOUT}#about-improve-social`} className="button-primary button-white w-inline-block">
                <div>See all product</div>
              </a>
              <a href={`${ROUTES.ABOUT}#general-contact`} className="hyperlink-tertiary hyperlink-white w-inline-block">
                <div className="hyperlink-text">Let&#x27;s discuss</div>
                <img src={ImgArrowWhite} alt="" className="arrow-icon" />
              </a>
            </div>
          </div>
          <div className="block-prefooter-image">
            <img src={ImgPreFooterIllustration} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PreFooter;
