import React from "react";
import { Link } from "react-router-dom";

import PreFooter from "./preFooter";

import * as ROUTES from "../../constants/routes";

import ImgSpotify from "../../assets/Spotify.svg";
import ImgLinkedIn from "../../assets/LInkedIn.svg";
import ImgInstagram from "../../assets/Instagram.svg";

function Footer() {
  return (
    <div className="section-footer">
      <div className="wrapper">
        <div className="footer-main-column w-row">
          <div className="footer-column-1 w-col w-col-1 w-col-stack">
            <div className="footer-title">Sitemap</div>
            <Link to={ROUTES.LANDING} className="footer-text-link">
              Home
            </Link>
            <Link to={ROUTES.ABOUT} className="footer-text-link">
              About
            </Link>
            <a href={`${ROUTES.ABOUT}#faq`} className="footer-text-link">
              FAQ
            </a>
            <a href={`${ROUTES.ABOUT}#general-contact`} className="footer-text-link">
              Contact
            </a>
          </div>
          <div className="footer-column-2 w-col w-col-3 w-col-stack">
            <div className="footer-title">Upcoming Graduates</div>
            <Link to={ROUTES.SYLLABUS} className="footer-text-link">
              Incubation program
            </Link>
            <a href={`${ROUTES.OAKNUT}#oaknut-suggest-city`} className="footer-text-link">
              Suggest your campus or city
            </a>
            <Link
              to={ROUTES.COURSES.replace(":id", 32).replace(
                ":slug",
                "upcoming-and-new-graduates-job-application-preparation-guide"
              )}
              className="footer-text-link"
            >
              Job Application Preparation Class
            </Link>
          </div>
          <div className="footer-column-3 w-col w-col-2 w-col-stack">
            <div className="footer-title">Young Professionals</div>
            <Link to={ROUTES.SCHOOL_FOR_PROFESSIONAL} className="footer-text-link">
              School for Professionals
            </Link>
            <a href={`${ROUTES.SCHOOL_FOR_PROFESSIONAL}#oaktree-partnership`} className="footer-text-link">
              Open a class with us
            </a>
            <a href={`${ROUTES.CAREER_CAPITAL}?tab=be-mentor#oaktree-apply-mentor`} className="footer-text-link">
              Be a mentor or a thought leader
            </a>
          </div>
          <div className="footer-column-4 w-col w-col-3 w-col-stack">
            <div className="footer-title">Companies and Org.</div>
            <a href={`${ROUTES.HR_STRATEGY}#partner-coach`} className="footer-text-link">
              HR Strategic Coach and Partner
            </a>
            <a
              href="https://wa.me/6281221221500?text=Hi%2C%20aku%20mau%20tanya%2F%20request%2F%20book%20call%20tentang...."
              target="_blank"
              rel="noopener noreferrer"
              className="footer-text-link"
            >
              Book a consultation call
            </a>
            <Link to={ROUTES.TALENT_SOLUTIONS} className="footer-text-link">
              Talent Solutions
            </Link>
          </div>
          <div className="footer-column-5 w-col w-col-3 w-col-stack">
            <div className="footer-title">Programs and Courses</div>
            <a href={`${ROUTES.CAREER_CAPITAL}#oaktree-apply-mentor`} className="footer-text-link">
              Career Coaching
            </a>
            <a href={`${ROUTES.SCHOOL_FOR_PROFESSIONAL}#oaktree-courses`} className="footer-text-link">
              Courses and Specialization
            </a>
            <a href={`${ROUTES.HR_STRATEGY}#partner-coach`} className="footer-text-link">
              Corporate Programs
            </a>
            <a href={`${ROUTES.HR_STRATEGY}#partner-coach`} className="footer-text-link">
              Executive Coaching
            </a>
          </div>
        </div>
        <div className="block-footer-copyright">
          <a
            href="https://open.spotify.com/show/65GgL8A4JOqYMXFXCguTyY?si=RhI3qsuiRVe3loveBcLctQ"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-social-media-icon w-inline-block"
          >
            <img src={ImgSpotify} alt="" />
          </a>
          <a
            href="https://www.linkedin.com/company/oaksociety/"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-social-media-icon w-inline-block"
          >
            <img src={ImgLinkedIn} alt="" />
          </a>
          <a
            href="http://www.instagram.com/oaks.co"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-social-media-icon w-inline-block"
          >
            <img src={ImgInstagram} alt="" />
          </a>
          <div className="footer-copyright-text">Copyright © 2019 Oaks Co.</div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
export { PreFooter };
