import React from "react";
import { Link } from "react-router-dom";

import SEO from "../Seo";
import { GeneralEmail } from "../EmailForm";
import { PreFooter } from "../Footer";

import * as ROUTES from "../../constants/routes";

import ImgArrowTertiary from "../../assets/Arrow-Tertiary.svg";
import ImgUniversity from "../../assets/University.svg";
import ImgSignificantRole from "../../assets/Significant-role.svg";
import ImgTransformTeam from "../../assets/Transform-team.svg";
import ImgResult from "../../assets/Result.svg";
import ImgCustomizedIllustration from "../../assets/Customized-illustration-Copy.svg";

const Oakwood = () => (
  <>
    <SEO
      title="Oakwood - University and Company"
      description="Imagine how much the world can gain if universities and businesses can come together to make it smoother for our graduates to succeed in the working world?"
    />
    <div className="section-single-hero">
      <div className="wrapper w-clearfix">
        <div className="h1">
          Together, we can make a greater story of our future through the success of our younger generation
        </div>
        <div className="bodytext">
          Imagine how much the world can gain if universities and businesses can come together
          <br />
          to make it smoother for our graduates to succeed in the working world?
        </div>
        <div className="block-two-row block-two-row-cards">
          <img src={ImgUniversity} alt="" className="block-two-row-image" />
          <div className="h3 block-two-row-title">
            If we infuse the needs of the working world to the curriculum, our upcoming graduates can easily be absorbed
            by businesses.
          </div>
          <div className="bodytext">
            It is the Teaching Factory (TEFA) model that inspires us. We can bring professionals and organizations to
            help adapt your practicum or other teaching activities to the needs of the business
          </div>
          <div className="block-button">
            <a href={`${ROUTES.UNIVERSITY}#oakwood-apply-university`} className="button-primary w-inline-block">
              <div>Collaborate with us</div>
            </a>
            <Link to={ROUTES.UNIVERSITY} className="hyperlink-tertiary w-inline-block">
              <div className="hyperlink-text">Explore our supports</div>
              <img src={ImgArrowTertiary} alt="" className="arrow-icon" />
            </Link>
          </div>
        </div>
        <div className="block-two-row block-two-row-cards">
          <img src={ImgSignificantRole} alt="" className="block-two-row-image" />
          <div className="h3 block-two-row-title">
            We believe in the significant role of companies, as employers, to influence the transformation of our people
            in the society
          </div>
          <div className="bodytext">
            We can help companies to acquire, retain, and develop their talents by providing setup assistance when
            building their HR architecture (HR Strategic Coach &amp; Partner) or to assist the implementation of their
            HR activities (Talent Solutions)
          </div>
          <div className="block-button">
            <a
              href={`${ROUTES.HR_STRATEGY}#general-contact`}
              className="button-primary button-primary-full w-inline-block"
            >
              <div>Partner with us</div>
            </a>
            <Link to={ROUTES.TALENT_SOLUTIONS} className="hyperlink-tertiary w-inline-block">
              <div className="hyperlink-text">Talent Solutions</div>
              <img src={ImgArrowTertiary} alt="" className="arrow-icon" />
            </Link>
            <a
              href={`${ROUTES.HR_STRATEGY}#partner-coach`}
              className="hyperlink-tertiary hyperlink-tertiary-left-margin w-inline-block"
            >
              <div className="hyperlink-text">HR Strategic Coach and Partner</div>
              <img src={ImgArrowTertiary} alt="" className="arrow-icon" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div id="oakwood-personas" className="section-personas">
      <div className="wrapper w-clearfix">
        <div className="h2 h2-section-personas">
          Why partner with us?
          <br />
          &zwj;
        </div>
        <div className="block-three-row">
          <img src={ImgTransformTeam} alt="" className="user-personas-student" />
          <div className="h4 h4-user-personas-text">Reliable transformation team</div>
          <div className="bodytext">
            Our team consists of world&#x27;s best universities certificate holder, consultants, high-position
            multinational experts, and unicorn players who are eager to assist both universities and businesses to
            transform.
          </div>
        </div>
        <div className="block-three-row">
          <img src={ImgResult} alt="" className="user-personas-student" />
          <div className="h4 h4-user-personas-text">Result over price</div>
          <div className="bodytext">
            We are aspired to shape a greater future for our youth, thus we focus on engaging as much universities and
            companies as possible by offering 80-90% lower price than the market.
          </div>
        </div>
        <div className="block-three-row">
          <img src={ImgCustomizedIllustration} alt="" className="user-personas-student" />
          <div className="h4 h4-user-personas-text">Lasting impact</div>
          <div className="bodytext">
            We hope that we can support each organization for only once, that is why we focus on creating impact that
            lasts: not only we assist you to get the fish, we also coach you the detail method how to get the fish.
          </div>
        </div>
      </div>
    </div>
    <GeneralEmail />
    <PreFooter />
  </>
);

export default Oakwood;
