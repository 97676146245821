import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import SEO from "../Seo";
import { SchoolProfessionalEmail } from "../EmailForm";
import { PreFooter } from "../Footer";

import * as ROUTES from "../../constants/routes";

import ImgArrowTertiary from "../../assets/Arrow-Tertiary.svg";
import ImgWhatsapp from "../../assets/icon-whatsapp.svg";
import ImgOaktreeSchoolHero from "../../assets/Oaktree---school-professional---hero.svg";
import ImgCourse1 from "../../assets/Course-1.png";
import ImgCorporatePartnership from "../../assets/Corporate-partnership.svg";
import ImgApplyIllustration from "../../assets/1.-Apply-illustration.svg";
import ImgRegisterIllustration from "../../assets/Register-Illustration.svg";
import ImgPaymentIllustration from "../../assets/Payment-Illustration.svg";
import ImgLearnIllustration from "../../assets/Learn-illustration.svg";

const COURSES_API_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}/api/post?type=courses`;
const IMAGE_API_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}/asset`;

const SchoolForProfessional = () => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const coursesData = [];
    axios
      .get(COURSES_API_ENDPOINT)
      .then(res => {
        res.data.data.forEach(course => {
          coursesData.push({
            id: course.id,
            title: course.post_title,
            excerpt: course.post_excerpt,
            slug: course.post_name,
            featureImage: course.post_meta[0] ? `${IMAGE_API_ENDPOINT}/${course.post_meta[0].meta_value}` : ImgCourse1,
          });
        });

        setCourses(coursesData);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <SEO title="Oaktree - School for professional" description="Now you can speed up your growth anytime" />
      <div className="section-hero">
        <div className="wrapper w-clearfix">
          <div className="block-heroimage responsive-image">
            <img src={ImgOaktreeSchoolHero} alt="" />
          </div>
          <div className="block-herotext">
            <div className="h1">Now you can speed up your growth anytime</div>
            <div className="bodytext">
              We understand that it can take dozens of millions IDR and scarcity of your leader&#x27;s time to grow.
              That is why we are here, to support you speed up your growth regardless.
            </div>
            <div className="block-button">
              <a href="#oaktree-courses" className="button-primary w-inline-block">
                <div>Explore courses</div>
              </a>
              <a
                href="#oaktree-how-it-works"
                className="hyperlink-tertiary hyperlink-tertiary-with-badge w-inline-block"
              >
                <div className="hyperlink-text">Learn how it  works</div>
                <img src={ImgArrowTertiary} alt="" className="arrow-icon" />
              </a>
            </div>
          </div>
          <div className="block-heroimage">
            <img src={ImgOaktreeSchoolHero} alt="" />
          </div>
        </div>
      </div>
      <div id="oaktree-courses" className="section-course">
        <div className="wrapper align-center w-clearfix">
          <div className="h2 h2-section-course">Explore our professional development programs</div>
          <div className="bodytext align-center bodytext-section-course">
            These programs are personally developed by ex-consultants, unicorn&#x27;s practitioners, multinational
            experts in a wide range of topics, and expanding. Students will learn mostly through close mentorship in a
            quick win format that allows them to immediately apply their experience at work to the practice. Explore
            below to find the professional development program that fits you.
          </div>
          {courses.map(course => (
            <Link
              to={ROUTES.COURSES.replace(":id", course.id).replace(":slug", course.slug)}
              className="block-course-card w-inline-block"
            >
              <img src={course.featureImage} alt="" />
            </Link>
          ))}
        </div>
      </div>
      <div id="oaktree-partnership" className="section-corporate-partnership">
        <div className="wrapper">
          <div className="block-prefooter w-clearfix">
            <div className="block-prefooter-text">
              <div className="h2 h2-section-prefooter">
                <strong className="bold-text">Corporate partnership</strong>
              </div>
              <div className="bodytext bodytext-section-prefooter">
                If you are a corporate trying to make our programs customized and delivered in-house, feel free to
                contact us here to discuss a more interesting package
              </div>
              <div className="button-primary button-white button-whatsapp-corporate">
                <a
                  href="https://wa.me/6281221221500?text=Hi%2C%20aku%20mau%20tanya%2F%20request%2F%20book%20call%20tentang...."
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-link whatsapp-link whatsapp-corporate w-inline-block"
                >
                  <img src={ImgWhatsapp} alt="" className="ico-whatsapp" />
                  <div className="nav-link-text">
                    <strong>+6281221221500</strong>
                  </div>
                </a>
              </div>
            </div>
            <div className="block-prefooter-image">
              <img src={ImgCorporatePartnership} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div id="oaktree-how-it-works" className="section-how-it-works">
        <div className="wrapper w-clearfix">
          <div className="h2 h2-section-personas">Get your learning in 4 steps</div>
          <div className="bodytext bodytext-section-personas">
            We have made it highly affordable for you to speed up your learning to support your career growth in just 4
            easy steps
          </div>
          <div className="block-four-row">
            <img src={ImgApplyIllustration} alt="" className="three-row-images" />
            <div className="four-row-title">Explore</div>
            <div className="bodytext bodytext-section-four-row">
              Explore programs, details, and date of enrollment before registering
              {/* <a href="#" data-w-id="6554198c-a545-aafe-3306-907936ffdeb3" className="inline-link inline-tnc"></a> */}
            </div>
          </div>
          <div className="block-four-row">
            <img src={ImgRegisterIllustration} alt="" className="three-row-images" />
            <div className="four-row-title">Register</div>
            <div className="bodytext bodytext-section-four-row">
              Register yourself before the class reach maximum capacity (between 5-10 pax/class)
              {/* <a href="#" className="inline-link"></a> */}
            </div>
          </div>
          <div className="block-four-row">
            <img src={ImgPaymentIllustration} alt="" className="three-row-images" />
            <div className="four-row-title">Make payment</div>
            <div className="bodytext bodytext-section-four-row">
              We will be waiting for your payment documentation after we provide you the invoice. However, some programs
              may have a selection test
              {/* <a href="#" className="inline-link">
                selection test.
              </a> */}
            </div>
          </div>
          <div className="block-four-row">
            <img src={ImgLearnIllustration} alt="" className="three-row-images" />
            <div className="four-row-title">Learn</div>
            <div className="bodytext bodytext-section-four-row">
              Get yourself certified after completing the final coursework and assisted practice.
              {/* <a href="#" className="inline-link"></a> */}
            </div>
          </div>
        </div>
      </div>
      <SchoolProfessionalEmail />
      <PreFooter />
    </>
  );
};

export default SchoolForProfessional;
