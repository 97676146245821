import React, { useState } from "react";
import axios from "axios";

const NeedSponsorEmail = () => {
  const [email, setEmail] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [phone, setPhone] = useState("");
  const [companyPosition, setCompanyPosition] = useState("");
  const [careerType, setCareerType] = useState("Mentor for career discussion");
  const [experience, setExperience] = useState("");
  const [whyNeed, setWhyNeed] = useState("");
  const [qualification, setQualification] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [btnText, setBtnText] = useState("Submit");
  const [failStyle, setFailStyle] = useState({ display: "none" });
  const [doneStyle, setDoneStyle] = useState({ display: "none", marginTop: 10 });

  const clearForm = () => {
    setEmail("");
    setLinkedIn("");
    setPhone("");
    setCompanyPosition("");
    setCareerType("Mentor for career discussion");
    setExperience("");
    setWhyNeed("");
    setQualification("");
    setBtnDisabled(false);
    setBtnText("Submit");
  };

  const onSubmit = event => {
    setFailStyle({ display: "none" });
    setDoneStyle({ display: "none", marginTop: 10 });
    setBtnText("Please wait....");
    setBtnDisabled(true);

    const MailAPI = `${process.env.REACT_APP_API_ENDPOINT}/api/inbox`;
    const axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const formData = new FormData();
    formData.append("Email-address", email);
    formData.append("LinkedIn-Profile", linkedIn);
    formData.append("Phone-/-Whatsapp", phone);
    formData.append("Your-Company,-and-Current-Position", companyPosition);
    formData.append("Type-of-Career-Sponsor-that-You-Need", careerType);
    formData.append("Tell-us-the-best-thing-from-your-experience", experience);
    formData.append("Why-do-you-need-a-career-sponsor", whyNeed);
    formData.append("Do-you-have-any-specific-qualification-for-the-career-sponsor?", qualification);
    formData.append("Mail-From", process.env.REACT_APP_WEBSITE_URL);
    formData.append("Mail-Reply", email);
    formData.append("Mail-Form", "Need Sponsor or Mentor Form");
    formData.append("Mail-Category", "need-sponsor-or-mentor-form");
    formData.append("Mail-Subject", "Need Sponsor or Mentor Form Submitted");
    formData.append("Mail-Title", `${email} - ${phone}`);
    formData.append("Mail-Excerpt", companyPosition);
    formData.append("Mail-Content", careerType);

    axios
      .post(MailAPI, formData, axiosConfig)
      .then(res => {
        clearForm();
        if (res.data.code === 200) {
          setDoneStyle({ display: "block", marginTop: 10 });
        } else {
          setFailStyle({ display: "block" });
        }
      })
      .catch(err => {
        setFailStyle({ display: "block" });
        console.log(err);
      });

    event.preventDefault();
  };

  return (
    <div className="block-oakwood-hr-contact-form hr-sponsor-form block-oaktree-mentor-form w-form">
      <form
        id="wf-form-Need-sponsor"
        name="wf-form-Need-sponsor"
        data-name="Need-sponsor"
        action="submitform"
        onSubmit={onSubmit}
      >
        <div className="block-form-two-row-oaknut">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            className="form-text-field w-input"
            maxLength="256"
            name="email"
            placeholder="Type your email"
            id="email"
            value={email}
            onChange={event => setEmail(event.target.value)}
            required
          />
        </div>
        <div className="block-form-two-row-oaknut block-form-two-row-oaknut-children">
          <label htmlFor="linkedIn" className="form-label">
            Your Linkedin Profile
          </label>
          <input
            type="text"
            className="form-text-field w-input"
            maxLength="256"
            name="linkedIn"
            placeholder="Ex.http://"
            id="linkedIn"
            value={linkedIn}
            onChange={event => setLinkedIn(event.target.value)}
          />
        </div>
        <div className="block-form-two-row-oaknut">
          <label htmlFor="phone" className="form-label">
            Phone / Whatsapp
          </label>
          <input
            type="text"
            className="form-text-field w-input"
            maxLength="256"
            name="phone"
            placeholder="Type active whatsapp or phone number"
            id="phone"
            value={phone}
            onChange={event => setPhone(event.target.value)}
            required
          />
        </div>
        <div className="block-form-two-row-oaknut block-form-two-row-oaknut-children">
          <label htmlFor="companyPosition" className="form-label">
            Your Company, and Current Position
          </label>
          <input
            type="text"
            className="form-text-field w-input"
            maxLength="256"
            name="companyPosition"
            placeholder="Type your company and your position"
            id="companyPosition"
            value={companyPosition}
            onChange={event => setCompanyPosition(event.target.value)}
            required
          />
        </div>
        <label htmlFor="careerType" className="form-label">
          Type of Career Sponsor that You Need
        </label>
        <select
          id="careerType"
          name="careerType"
          className="form-text-field w-select"
          value={careerType}
          onChange={event => setCareerType(event.target.value)}
          required
        >
          <option value="Mentor for career discussion">Mentor for career discussion</option>
          <option value="Speaker for an event">Speaker for an event</option>
          <option value="Expert to learn from through 1x casual chat">
            Expert to learn from through 1x casual chat
          </option>
          <option value="A professional network that I can join">A professional network that I can join</option>
        </select>
        <label htmlFor="experience" className="form-label">
          Tell us the best thing from your experience
        </label>
        <textarea
          placeholder="Tell us how can we might help you"
          maxLength="5000"
          id="experience"
          name="experience"
          className="form-text-area w-input"
          value={experience}
          onChange={event => setExperience(event.target.value)}
          required
        />
        <label htmlFor="whyNeed" className="form-label">
          Why do you need a career sponsor
        </label>
        <textarea
          placeholder="Why do you need a career sponsor"
          maxLength="5000"
          id="whyNeed"
          name="whyNeed"
          className="form-text-area w-input"
          value={whyNeed}
          onChange={event => setWhyNeed(event.target.value)}
          required
        />
        <label htmlFor="qualification" className="form-label">
          Do you have any specific qualification for the career sponsor?
        </label>
        <textarea
          placeholder="Do you have any specific qualification for the career sponsor?"
          maxLength="5000"
          id="qualification"
          name="qualification"
          className="form-text-area w-input"
          value={qualification}
          onChange={event => setQualification(event.target.value)}
          required
        />
        <input type="submit" value={btnText} className="button-primary w-button" disabled={btnDisabled} />
      </form>
      <div className="w-form-done" style={doneStyle}>
        <div>Thank you! Your submission has been received!</div>
      </div>
      <div className="w-form-fail" style={failStyle}>
        <div>Oops! Something went wrong while submitting the form.</div>
      </div>
    </div>
  );
};

export default NeedSponsorEmail;
