import React, { useState } from "react";
import axios from "axios";

const SchoolProfessionalEmail = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [expertise, setExpertise] = useState("");
  const [courseName, setCourseName] = useState("");
  const [description, setDescription] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [btnText, setBtnText] = useState("Submit");
  const [failStyle, setFailStyle] = useState({ display: "none" });
  const [doneStyle, setDoneStyle] = useState({ display: "none", marginTop: 10 });

  const clearForm = () => {
    setFullName("");
    setEmail("");
    setExpertise("");
    setCourseName("");
    setDescription("");
    setBtnDisabled(false);
    setBtnText("Submit");
  };

  const onSubmit = event => {
    setFailStyle({ display: "none" });
    setDoneStyle({ display: "none", marginTop: 10 });
    setBtnText("Please wait....");
    setBtnDisabled(true);

    const MailAPI = `${process.env.REACT_APP_API_ENDPOINT}/api/inbox`;
    const axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const formData = new FormData();
    formData.append("Full-name", fullName);
    formData.append("Email-address", email);
    formData.append("Area-of-expertise", expertise);
    formData.append("Course-name", courseName);
    formData.append("Description", description);
    formData.append("Mail-From", process.env.REACT_APP_WEBSITE_URL);
    formData.append("Mail-Reply", email);
    formData.append("Mail-Form", "Suggest Course Form");
    formData.append("Mail-Category", "suggest-course-form");
    formData.append("Mail-Subject", "Suggest Course Form Submitted");
    formData.append("Mail-Title", `${fullName} - ${expertise}`);
    formData.append("Mail-Excerpt", courseName);
    formData.append("Mail-Content", description);

    axios
      .post(MailAPI, formData, axiosConfig)
      .then(res => {
        clearForm();
        if (res.data.code === 200) {
          setDoneStyle({ display: "block", marginTop: 10 });
        } else {
          setFailStyle({ display: "block" });
        }
      })
      .catch(err => {
        setFailStyle({ display: "block" });
        console.log(err);
      });

    event.preventDefault();
  };

  return (
    <div id="oaktree-suggest-course" className="section-suggest-course">
      <div className="wrapper">
        <div className="block-oakwood-hr-contact-form hr-sponsor-form block-suggest-course-form w-form">
          <form
            id="wf-form-Be-mentor"
            name="wf-form-Be-mentor"
            data-name="Be-mentor"
            action="submitform"
            onSubmit={onSubmit}
          >
            <div className="h2">Suggest a course</div>
            <div className="bodytext bodytext-suggest-cpurse">
              You can&#x27;t find your area of interest or expertise? Suggest us below, we will try to find the expert
              and open the class within the next&nbsp;
              <strong className="inline-bold">6 months</strong>
              {"."}
            </div>
            <div className="block-form-two-row-oaknut block-form-suggest-course">
              <label htmlFor="fullName" className="form-label">
                Full name
              </label>
              <input
                type="text"
                className="form-text-field w-input"
                maxLength="256"
                name="fullName"
                placeholder="Type your full name"
                id="fullName"
                value={fullName}
                onChange={event => setFullName(event.target.value)}
                required
              />
            </div>
            <div className="block-form-two-row-oaknut block-form-two-row-oaknut-children block-form-suggest-course">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-text-field w-input"
                maxLength="256"
                name="email"
                placeholder="Type your Email"
                id="email"
                value={email}
                onChange={event => setEmail(event.target.value)}
                required
              />
            </div>
            <div className="block-form-two-row-oaknut block-form-suggest-course">
              <label htmlFor="expertise" className="form-label">
                Area of expertise
              </label>
              <select
                id="expertise"
                name="expertise"
                className="form-text-field w-select"
                value={expertise}
                onChange={event => setExpertise(event.target.value)}
                required
              >
                <option value="">Select one...</option>
                <option value="Student">Student</option>
                <option value="Employee">Employee</option>
              </select>
            </div>
            <div className="block-form-two-row-oaknut block-form-two-row-oaknut-children block-form-suggest-course">
              <label htmlFor="courseName" className="form-label">
                Course name
              </label>
              <input
                type="text"
                className="form-text-field w-input"
                maxLength="256"
                name="courseName"
                placeholder="Type your suggestion course"
                id="courseName"
                value={courseName}
                onChange={event => setCourseName(event.target.value)}
                required
              />
            </div>
            <label htmlFor="description" className="form-label">
              Description
            </label>
            <textarea
              placeholder="Tell us how can we might help you"
              maxLength="5000"
              id="description"
              name="description"
              className="form-text-area w-input"
              value={description}
              onChange={event => setDescription(event.target.value)}
              required
            />
            <input type="submit" value={btnText} className="button-primary w-button" disabled={btnDisabled} />
          </form>
          <div className="w-form-done" style={doneStyle}>
            <div>Thank you! Your submission has been received!</div>
          </div>
          <div className="w-form-fail" style={failStyle}>
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchoolProfessionalEmail;
