import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import axios from "axios";
import Modal from "react-awesome-modal";

import SEO from "../Seo";
import { GeneralEmail } from "../EmailForm";
import { PreFooter } from "../Footer";

import ImgArrowTertiary from "../../assets/Arrow-Tertiary.svg";
import ImgOakwoodHRCoaching from "../../assets/Oakwood-HR-coaching-illustration.svg";
import ImgExploreIllustration from "../../assets/Explore-Illustration.svg";
import ImgInterviewIllustration from "../../assets/Interview--illustration.svg";
import ImgKickoffIllustration from "../../assets/Kickoff-Illustration.svg";
import ImgCompleteIllustration from "../../assets/Complete-illustration.svg";
import ImgTransformTeam from "../../assets/Transform-team.svg";
import ImgResult from "../../assets/Result.svg";
import ImgCustomizedIllustration from "../../assets/Customized-illustration-Copy.svg";

const HR_COACHING_API_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}/api/post?type=hr-coaching`;
const HR_PARTNERING_API_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}/api/post?type=hr-partnering`;

const HRStrategy = ({ location }) => {
  const [activeTab, setActiveTab] = useState("");
  const [programOptions, setProgramOptions] = useState({ partnering: [], coaching: [] });
  const [programs, setPrograms] = useState(null);
  const [price, setPrice] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [btnText, setBtnText] = useState("Submit");
  const [failStyle, setFailStyle] = useState({ display: "none" });
  const [doneStyle, setDoneStyle] = useState({ display: "none", marginTop: 10 });

  const clearForm = () => {
    setPrograms(null);
    setFullName("");
    setEmail("");
    setPhone("");
    setCompany("");
    setBtnDisabled(false);
    setBtnText("Submit");
    const checkboxes = document.querySelectorAll(".w-checkbox-input");
    for (let i = 0; i < checkboxes.length; i += 1) {
      checkboxes[i].checked = false;
    }
  };

  useEffect(() => {
    const urlQuery = queryString.parse(location.search);
    if (urlQuery.tab) {
      setActiveTab(urlQuery.tab);
    } else {
      setActiveTab("hr-partnering");
    }
  }, [location]);

  useEffect(() => {
    const programsData = { partnering: [], coaching: [] };
    axios
      .get(HR_PARTNERING_API_ENDPOINT)
      .then((resPartnering) => {
        resPartnering.data.data.forEach((partnering) => {
          programsData.partnering[partnering.id] = {
            id: partnering.id,
            title: partnering.post_title,
            price: Number.parseInt(partnering.post_excerpt, 10),
          };
        });

        axios
          .get(HR_COACHING_API_ENDPOINT)
          .then((resCoaching) => {
            resCoaching.data.data.forEach((coaching) => {
              programsData.coaching[coaching.id] = {
                id: coaching.id,
                title: coaching.post_title,
                price: Number.parseInt(coaching.post_excerpt, 10),
              };
            });

            setProgramOptions(programsData);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    let total = 0;
    if (programs) {
      programs.forEach((program) => {
        total += program.price;
      });
    }

    setPrice(total);
  }, [programs]);

  const onChecked = (id, group, checked) => {
    let items = programs ? [...programs] : [];
    if (checked) {
      items.push({ id, ...programOptions[group][id] });
    } else {
      items = programs.filter((program) => program.id !== id);
    }
    setPrograms(items);
  };

  const toggleModal = (flag) => {
    setShowModal(flag);
  };

  const onSubmit = (event) => {
    setFailStyle({ display: "none" });
    setDoneStyle({ display: "none", marginTop: 10 });
    setBtnText("Please wait....");
    setBtnDisabled(true);

    const MailAPI = `${process.env.REACT_APP_API_ENDPOINT}/api/inbox`;
    const axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const selectedPrograms = programs.map((program) => program.title);

    const formData = new FormData();
    formData.append("Full-name", fullName);
    formData.append("Email-address", email);
    formData.append("Company", company);
    formData.append("Phone-number", phone);
    formData.append("JSON-Selected-Programs", JSON.stringify(selectedPrograms));
    formData.append("Mail-From", process.env.REACT_APP_WEBSITE_URL);
    formData.append("Mail-Reply", email);
    formData.append("Mail-Form", "Oakwood HR. Partnering / Coaching Form");
    formData.append("Mail-Category", "oakwood-hr-partnering-coaching-form");
    formData.append("Mail-Subject", "Oakwood HR. Partnering / Coaching Form Submitted");
    formData.append("Mail-Title", `${fullName} - ${email}`);
    formData.append("Mail-Excerpt", phone);
    formData.append("Mail-Content", company);

    axios
      .post(MailAPI, formData, axiosConfig)
      .then((res) => {
        clearForm();
        if (res.data.code === 200) {
          setDoneStyle({ display: "block", marginTop: 10 });
          setTimeout(() => {
            toggleModal(false);
          }, 5000);
        } else {
          setFailStyle({ display: "block" });
        }
      })
      .catch((err) => {
        setFailStyle({ display: "block" });
        console.log(err);
      });

    event.preventDefault();
  };

  return (
    <>
      <SEO
        title="Oakwood - HR Strategy"
        description="We provide affordable support for new and transforming companies to develop and grow their talents either with the right and complete setup or HR architecture, whether through deep coaching or assisting the design of the HR architecture itself."
      />
      <div className="section-hero">
        <div className="wrapper w-clearfix">
          <div className="block-heroimage responsive-image">
            <img src={ImgOakwoodHRCoaching} alt="" />
          </div>
          <div className="block-herotext">
            <div className="h1">Complete your HR architecture with less than 4 months of your HR Head’s salary</div>
            <div className="bodytext">
              We provide affordable support for new and transforming companies to develop and grow their talents either
              with the right and complete setup or HR architecture, whether through deep coaching or assisting the
              design of the HR architecture itself.
            </div>
            <div className="block-button">
              <a
                href="https://wa.me/6281221221500?text=Hi%2C%20aku%20mau%20tanya%2F%20request%2F%20book%20call%20tentang...."
                target="_blank"
                rel="noopener noreferrer"
                className="button-primary button-tertiary w-inline-block"
              >
                <div className="badge-medium">
                  <div>Free</div>
                </div>
                <div>Book a call to discuss</div>
              </a>
              <a
                href="#oakwood-hr-how-it-works"
                className="hyperlink-tertiary hyperlink-tertiary-with-badge w-inline-block"
              >
                <div className="hyperlink-text">Learn how it  works</div>
                <img src={ImgArrowTertiary} alt="" className="arrow-icon" />
              </a>
            </div>
          </div>
          <div className="block-heroimage">
            <img src={ImgOakwoodHRCoaching} alt="" />
          </div>
        </div>
      </div>
      <div id="partner-coach" className="section-hr-programs">
        <div className="wrapper">
          <div className="h2 h2-section-personas">
            Why do we call ourselves as
            <br />
            &quot;HR Strategic Coach and Partner&quot;
          </div>
          <div className="bodytext bodytext-section-personas">
            We are not here to offer you a hands-off approach when it comes to building your HR architecture.
            <br />
            We require a partnership between us, as your strategic HR partner, and your team, whether it is the HR
            department or the management team.
            <br />
            <br />
            It is important for us to develop your team throughout the project as we are focusing on creating the impact
            that lasts: the knowledge and skill of your team. Below is the calculator to approximate the potential
            spending that may be needed to collaborate with us.
            <br />
            (spoiler: we offer you 80-90% lower price than the market)
          </div>
          <div data-duration-in="300" data-duration-out="100" className="multi-tab w-tabs">
            <div className="multi-tab-menu w-tab-menu">
              <a
                href="#/"
                data-w-tab="HR-Partnering"
                className={`tab-link w-inline-block w-tab-link${activeTab === "hr-partnering" ? " w--current" : ""}`}
              >
                <div>HR Partnering</div>
              </a>
              <a
                href="#/"
                data-w-tab="HR-Coaching"
                className={`tab-link w-inline-block w-tab-link${activeTab === "hr-coaching" ? " w--current" : ""}`}
              >
                <div>HR Coaching</div>
              </a>
            </div>
            <div className="multi-tab-content w-tab-content">
              <div
                data-w-tab="HR-Partnering"
                className={`tab-panel w-tab-pane${activeTab === "hr-partnering" ? " w--tab-active" : ""}`}
              >
                {programOptions.partnering.map((option) => (
                  <div className="block-hr-program-form w-form" key={option.id}>
                    <div className="block-hr-program-list">
                      <label className="w-checkbox block-checkbox w-clearfix">
                        <input
                          type="checkbox"
                          className="w-checkbox-input checkbox-hr-program"
                          onChange={(event) => onChecked(option.id, "partnering", event.target.checked)}
                        />
                        <span className="checkbox-label-hr-program w-form-label">{option.title}</span>
                      </label>
                    </div>
                  </div>
                ))}
              </div>
              <div
                data-w-tab="HR-Coaching"
                className={`tab-panel w-tab-pane${activeTab === "hr-coaching" ? " w--tab-active" : ""}`}
              >
                {programOptions.coaching.map((option) => (
                  <div className="block-hr-program-form w-form" key={option.id}>
                    <div className="block-hr-program-list">
                      <label className="w-checkbox block-checkbox w-clearfix">
                        <input
                          type="checkbox"
                          className="w-checkbox-input checkbox-hr-program"
                          onChange={(event) => onChecked(option.id, "coaching", event.target.checked)}
                        />
                        <span className="checkbox-label-hr-program w-form-label">{option.title}</span>
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="oaknut-syllabus">
            If you want to partner with us in the other HR area. More than the ones listed above please,&nbsp;
            <a
              href="https://wa.me/6281221221500?text=Hi%2C%20aku%20mau%20tanya%2F%20request%2F%20book%20call%20tentang...."
              target="_blank"
              rel="noopener noreferrer"
              className="oaknut-syllabus-link"
            >
              contact us here for more information
            </a>
          </div>
        </div>
      </div>
      <div id="oakwood-hr-how-it-works" className="section-hr-how-it-works">
        <div className="wrapper w-clearfix">
          <div className="h2 h2-section-personas h2-oakwood-hr-strategy">Collaborate with us in 4 simple steps</div>
          <div className="block-four-row">
            <img src={ImgExploreIllustration} alt="" className="three-row-images" />
            <div className="four-row-title">Book a call</div>
            <div className="bodytext bodytext-section-four-row">
              One of our best partners will contact you to set up either a meeting or a call to discuss the current
              issues, strategy, and desired state of your organization.
            </div>
          </div>
          <div className="block-four-row">
            <img src={ImgInterviewIllustration} alt="" className="three-row-images" />
            <div className="four-row-title">Setup a meeting</div>
            <div className="bodytext bodytext-section-four-row">
              We will provide you 1 (one) time HR strategy discussion meeting after the call (first step). During the
              meeting, we will also provide you a basic OD coaching to help you plan milestones of your HR architecture
              design.
            </div>
          </div>
          <div className="block-four-row">
            <img src={ImgKickoffIllustration} alt="" className="three-row-images" />
            <div className="four-row-title">Kick off</div>
            <div className="bodytext bodytext-section-four-row">
              If your organization and our partner come to an agreed point on the scope and price, signing an agreement
              and kicking off the project through a meeting are required to start the project.
            </div>
          </div>
          <div className="block-four-row">
            <img src={ImgCompleteIllustration} alt="" className="three-row-images" />
            <div className="four-row-title">Complete</div>
            <div className="bodytext bodytext-section-four-row">
              Our team will assist your university to complete the project based on the defined scope.
            </div>
          </div>
        </div>
      </div>
      <div id="oakwood-partner" className="section-personas">
        <div className="wrapper w-clearfix">
          <div className="h2 h2-section-personas">
            Why partner with us?
            <br />
            &zwj;
          </div>
          <div className="block-three-row">
            <img src={ImgTransformTeam} alt="" className="user-personas-student" />
            <div className="h4 h4-user-personas-text">Reliable transformation team</div>
            <div className="bodytext">
              Our team consists of world&#x27;s best universities certificate holder, consultants, high-position
              multinational experts, and unicorn players who are eager to assist both universities and businesses to
              transform.
            </div>
          </div>
          <div className="block-three-row">
            <img src={ImgResult} alt="" className="user-personas-student" />
            <div className="h4 h4-user-personas-text">Result over price</div>
            <div className="bodytext">
              We are aspired to shape a greater future for our youth, thus we focus on engaging as much universities and
              companies as possible by offering 80-90% lower price than the market.
            </div>
          </div>
          <div className="block-three-row">
            <img src={ImgCustomizedIllustration} alt="" className="user-personas-student" />
            <div className="h4 h4-user-personas-text">Lasting impact</div>
            <div className="bodytext">
              We hope that we can support each organization for only once, that is why we focus on creating impact that
              lasts: not only we assist you to get the fish, we also coach you the detail method how to get the fish.
            </div>
          </div>
        </div>
      </div>
      <GeneralEmail />
      <PreFooter />
      <Modal visible={showModal} width="600" height="300" effect="fadeInDown" onClickAway={() => toggleModal(false)}>
        <div className="block-overlay-100 block-overlay-popup-form">
          <div className="block-popup block-popup-hr" style={{ width: "100%" }}>
            <div className="w-form">
              <form
                id="wf-form-Be-mentor"
                name="wf-form-Be-mentor"
                data-name="Be-mentor"
                action="submitform"
                onSubmit={onSubmit}
              >
                <div className="block-form-two-row-oaknut block-form-suggest-course">
                  <label htmlFor="fullName" className="form-label">
                    Full name
                  </label>
                  <input
                    type="text"
                    className="form-text-field w-input"
                    maxLength="256"
                    name="fullName"
                    placeholder="Type your full name"
                    id="fullName"
                    value={fullName}
                    onChange={(event) => setFullName(event.target.value)}
                    required
                  />
                </div>
                <div className="block-form-two-row-oaknut block-form-two-row-oaknut-children block-form-suggest-course">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-text-field w-input"
                    maxLength="256"
                    name="email"
                    placeholder="Type your Email"
                    id="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    required
                  />
                </div>
                <div className="block-form-two-row-oaknut block-form-suggest-course">
                  <label htmlFor="company" className="form-label">
                    Company
                  </label>
                  <input
                    type="text"
                    className="form-text-field w-input"
                    maxLength="256"
                    name="company"
                    placeholder="Type your company name"
                    id="company"
                    value={company}
                    onChange={(event) => setCompany(event.target.value)}
                    required
                  />
                </div>
                <div className="block-form-two-row-oaknut block-form-two-row-oaknut-children block-form-suggest-course">
                  <label htmlFor="phone" className="form-label">
                    Phone number
                  </label>
                  <input
                    type="text"
                    className="form-text-field w-input"
                    maxLength="256"
                    name="phone"
                    placeholder="Type your phone number"
                    id="phone"
                    value={phone}
                    onChange={(event) => setPhone(event.target.value)}
                    required
                  />
                </div>
                <input type="submit" value={btnText} className="button-primary w-button" disabled={btnDisabled} />
              </form>
              <div className="w-form-done" style={doneStyle}>
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div className="w-form-fail" style={failStyle}>
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {price > 0 ? (
        <div className="section-pricing">
          <div className="wrapper w-clearfix">
            <div className="block-pricing-details">
              <div className="bodytext bodytext-pricing">Pricing</div>
              <div className="h3 h3-pricing">{`Rp.${new Intl.NumberFormat("id").format(price)}`}</div>
            </div>
            <div className="block-pricing-cta">
              <a
                href="#book-a-call"
                onClick={() => toggleModal(true)}
                className="button-primary button-tertiary button--pricing w-inline-block"
              >
                <div>Book a call to discuss</div>
              </a>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

HRStrategy.propTypes = { location: PropTypes.instanceOf(Object).isRequired };

export default HRStrategy;
