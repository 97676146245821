import React from "react";
import { Link } from "react-router-dom";

import SEO from "../Seo";
import { GeneralEmail } from "../EmailForm";
import { PreFooter } from "../Footer";

import * as ROUTES from "../../constants/routes";

import ImgArrowTertiary from "../../assets/Arrow-Tertiary.svg";
import ImgTalentSolutionIllustration from "../../assets/Talent-solution---illustration.svg";
import ImgHiring from "../../assets/Hiring.svg";
import ImgProbationIllustration from "../../assets/Probation-illustration.svg";
import ImgSkillIllustration from "../../assets/Skill-illustration.svg";
import ImgManagerIllustration from "../../assets/Manager-illustration.svg";
import ImgExecutiveIllustration from "../../assets/Executive-illustration.svg";

const TalentSolution = () => (
  <>
    <SEO title="Oakwood - Talent Solutions" description="Assisting the implementation of your talent management" />
    <div className="section-hero">
      <div className="wrapper w-clearfix">
        <div className="block-heroimage responsive-image">
          <img src={ImgTalentSolutionIllustration} alt="" />
        </div>
        <div className="block-herotext">
          <div className="h1">Assisting the implementation of your talent management</div>
          <div className="bodytext">
            We are aspired to bring all possible supports that revolve around fulfilling business needs on talents, from
            acquiring qualified talents until developing leadership within your organization
          </div>
          <div className="block-button">
            <a
              href="https://wa.me/6281221221500?text=Hi%2C%20aku%20mau%20tanya%2F%20request%2F%20book%20call%20tentang...."
              target="_blank"
              rel="noopener noreferrer"
              className="button-primary button-tertiary w-inline-block"
            >
              <div className="badge-medium">
                <div>Free</div>
              </div>
              <div>Book a call to discuss</div>
            </a>
            <a
              href="#oakwood-leadership-development"
              className="hyperlink-tertiary hyperlink-tertiary-with-badge w-inline-block"
            >
              <div className="hyperlink-text">Leadership development</div>
              <img src={ImgArrowTertiary} alt="" className="arrow-icon" />
            </a>
          </div>
        </div>
        <div className="block-heroimage">
          <img src={ImgTalentSolutionIllustration} alt="" />
        </div>
      </div>
    </div>
    <div id="oakwood-talent-lists" className="section-talent-personas">
      <div className="wrapper w-clearfix">
        <div className="block-three-row">
          <img src={ImgHiring} alt="" className="three-row-images" />
          <div className="h4 h4-three-row-align-left">Hiring</div>
          <div className="bodytext bodytext-three-row-align-left">
            We can provide you with selected best graduates equipped with business skills and leadership competencies
            that may not be covered by traditional educational institutions. After being incubated for 3 months, they
            are ready for your company.
          </div>
          <div className="block-button">
            <a
              href={`${ROUTES.OAKNUT}#oaknut-how-it-works`}
              className="button-primary button-primary-full w-inline-block"
            >
              <div>Take our incubated talents</div>
            </a>
            <Link to={ROUTES.SYLLABUS} className="hyperlink-tertiary w-inline-block">
              <div className="hyperlink-text">Read about our incubation program</div>
              <img src={ImgArrowTertiary} alt="" className="arrow-icon" />
            </Link>
          </div>
        </div>
        <div className="block-three-row">
          <img src={ImgProbationIllustration} alt="" className="three-row-images" />
          <div className="h4 h4-three-row-align-left">Probation</div>
          <div className="bodytext bodytext-three-row-align-left">
            We can optimize the probation period by equipping your new hire with basic business skills and leadership
            competencies that may not be covered by traditional educational institutions. Contact us for more info and
            customization
          </div>
          <div className="block-button">
            <a
              href="https://wa.me/6281221221500?text=Hi%2C%20aku%20mau%20tanya%2F%20request%2F%20book%20call%20tentang...."
              target="_blank"
              rel="noopener noreferrer"
              className="button-primary button-primary-full button-tertiary w-inline-block"
            >
              <div>Book a call to learn more</div>
            </a>
            <Link to={ROUTES.SYLLABUS} className="hyperlink-tertiary w-inline-block">
              <div className="hyperlink-text">Read a closely-similar program</div>
              <img src={ImgArrowTertiary} alt="" className="arrow-icon" />
            </Link>
          </div>
        </div>
        <div className="block-three-row">
          <img src={ImgSkillIllustration} alt="" className="three-row-images" />
          <div className="h4 h4-three-row-align-left">Skill Development</div>
          <div className="bodytext bodytext-three-row-align-left">
            We provide your talents with mentorship, technical coaching, certification-based development programs,
            expert connections, event-speaking opportunities, and other imaginable supports to boost up the growth of
            your talents.
          </div>
          <div className="block-button">
            <a
              href="https://wa.me/6281221221500?text=Hi%2C%20aku%20mau%20tanya%2F%20request%2F%20book%20call%20tentang...."
              target="_blank"
              rel="noopener noreferrer"
              className="button-primary button-primary-full button-tertiary w-inline-block"
            >
              <div>Book a call to discuss more</div>
            </a>
            <a href={`${ROUTES.SCHOOL_FOR_PROFESSIONAL}#oaktree-courses`} className="hyperlink-tertiary w-inline-block">
              <div className="hyperlink-text">Check our available courses</div>
              <img src={ImgArrowTertiary} alt="" className="arrow-icon" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div id="oakwood-leadership-development" className="section-leadership-development">
      <div className="wrapper w-clearfix">
        <div className="h2 h2-section-personas">Leadership Development Program</div>
        <div className="bodytext bodytext-section-personas">
          We believe that leadership defines the success direction of your organization and talents,
          <br />
          yet companies often unsure with what kind of development that can be provided in order to result to higher
          productivity.
        </div>
        <div className="block-two-row">
          <img src={ImgManagerIllustration} alt="" className="block-two-row-image" />
          <div className="h3 block-two-row-title">First-time Manager Program</div>
          <div className="bodytext">
            The First Time Manager Development Program is designed to equip new leaders with a set of basic management
            habits that can naturally adjust them to the new responsibility of managing others.
            <br />
            &zwj;
          </div>
          <div className="block-button">
            <a href="#general-contact" className="button-primary button-primary-full w-inline-block">
              <div>Collaborate with us</div>
            </a>
          </div>
        </div>
        <div className="block-two-row">
          <img src={ImgExecutiveIllustration} alt="" className="block-two-row-image" />
          <div className="h3 block-two-row-title">Executive Coaching Program</div>
          <div className="bodytext">
            The Executive Coaching Program is designed around a framework of core and self-organized learning, supported
            by individual coaching, that enables participants to build a personalized leadership development program
            structured around a clear set of criteria.
          </div>
          <div className="block-button">
            <a href="#general-contact" className="button-primary button-tertiary button-primary-full w-inline-block">
              <div>Find this interesting? Partner with us!</div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <GeneralEmail />
    <PreFooter />
  </>
);

export default TalentSolution;
