import React, { useState } from "react";
import axios from "axios";

const BeMentorEmail = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [workExperience, setWorkExperience] = useState("");
  const [cvFile, setCvFile] = useState(null);
  const [cvKey, setCvKey] = useState(Date.now());
  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  const [checkbox3, setCheckbox3] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [btnText, setBtnText] = useState("Submit");
  const [failStyle, setFailStyle] = useState({ display: "none" });
  const [doneStyle, setDoneStyle] = useState({ display: "none", marginTop: 10 });

  const clearForm = () => {
    setFullName("");
    setEmail("");
    setPhone("");
    setWorkExperience("");
    setCvFile(null);
    setCvKey(Date.now());
    setCheckbox1(false);
    setCheckbox2(false);
    setCheckbox3(false);
    setBtnDisabled(false);
    setBtnText("Submit");
  };

  const onSubmit = event => {
    setFailStyle({ display: "none" });
    setDoneStyle({ display: "none", marginTop: 10 });
    setBtnText("Please wait....");
    setBtnDisabled(true);

    const MailAPI = `${process.env.REACT_APP_API_ENDPOINT}/api/inbox`;
    const axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const formData = new FormData();
    formData.append("Full-name", fullName);
    formData.append("Email-address", email);
    formData.append("Phone-/-Whatsapp", phone);
    formData.append("Your-Length-of-Work-Experience", workExperience);
    formData.append("CV-File", cvFile);
    formData.append("I'm-open-to-be-a-mentor-and-help-guide-others-throughout-their-career", checkbox1);
    formData.append("I-want-to-share-my-knowledge-and-experiences-by-speaking-in-public-events", checkbox2);
    formData.append(
      "I-am-an-expert,-I-wish-I-can-build-a-syllabus,-open-a-class,-and-teach-young-professionals",
      checkbox3
    );
    formData.append("Mail-From", process.env.REACT_APP_WEBSITE_URL);
    formData.append("Mail-Reply", email);
    formData.append("Mail-Form", "Be Our Mentor Form");
    formData.append("Mail-Category", "be-our-mentor-form");
    formData.append("Mail-Subject", "Be Our Mentor Form Submitted");
    formData.append("Mail-Title", `${fullName} - ${email}`);
    formData.append("Mail-Excerpt", phone);
    formData.append("Mail-Content", "I'm open to be a mentor");

    axios
      .post(MailAPI, formData, axiosConfig)
      .then(res => {
        clearForm();
        if (res.data.code === 200) {
          setDoneStyle({ display: "block", marginTop: 10 });
        } else {
          setFailStyle({ display: "block" });
        }
      })
      .catch(err => {
        setFailStyle({ display: "block" });
        setBtnText("Submit");
        setBtnDisabled(false);
        console.log(err);
      });

    event.preventDefault();
  };

  return (
    <div className="block-oakwood-hr-contact-form hr-sponsor-form block-oaktree-mentor-form w-form">
      <form
        id="wf-form-Be-mentor"
        name="wf-form-Be-mentor"
        data-name="Be-mentor"
        action="submitform"
        onSubmit={onSubmit}
        encType="multipart/form-data"
      >
        <label htmlFor="fullName" className="form-label">
          Full name
        </label>
        <input
          type="text"
          className="form-text-field w-input"
          maxLength="256"
          name="fullName"
          placeholder="Type your full name"
          id="fullName"
          value={fullName}
          onChange={event => setFullName(event.target.value)}
          required
        />
        <div className="block-form-two-row-oaknut block-form-two-row-oaktree-mentor">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            className="form-text-field w-input"
            maxLength="256"
            name="email"
            placeholder="Type your email"
            id="email"
            value={email}
            onChange={event => setEmail(event.target.value)}
            required
          />
        </div>
        <div className="block-form-two-row-oaknut block-form-two-row-oaknut-children block-form-two-row-oaktree-mentor">
          <label htmlFor="phone" className="form-label">
            Phone / Whatsapp
          </label>
          <input
            type="text"
            className="form-text-field w-input"
            maxLength="256"
            name="phone"
            placeholder="Type active whatsapp or phone number"
            id="phone"
            value={phone}
            onChange={event => setPhone(event.target.value)}
            required
          />
        </div>
        <div className="block-form-two-row-oaknut block-form-two-row-oaktree-mentor">
          <label htmlFor="workExperience" className="form-label">
            Your Length of Work Experience
          </label>
          <input
            type="text"
            className="form-text-field w-input"
            maxLength="256"
            name="workExperience"
            placeholder="Ex: 5 Years"
            id="workExperience"
            value={workExperience}
            onChange={event => setWorkExperience(event.target.value)}
            required
          />
        </div>
        <div className="block-form-two-row-oaknut block-form-two-row-oaknut-children block-form-two-row-oaktree-mentor">
          <label htmlFor="cvFile" className="form-label">
            Upload CV
          </label>
          <input
            type="file"
            className="form-text-field w-input"
            name="cvFile"
            key={cvKey}
            onChange={event => setCvFile(event.target.files[0])}
            accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            placeholder="pdf or .doc - Max 1.5mb"
            id="cvFile"
            required
          />
        </div>
        <label className="w-checkbox block-checkbox">
          <input
            type="checkbox"
            id="checkbox1"
            name="checkbox1"
            className="w-checkbox-input"
            checked={checkbox1}
            onChange={event => setCheckbox1(event.target.checked)}
          />
          <span className="w-form-label">
            I&#x27;m open to be a mentor and help guide others throughout their career
          </span>
        </label>
        <label className="w-checkbox block-checkbox">
          <input
            type="checkbox"
            id="checkbox2"
            name="checkbox2"
            className="w-checkbox-input"
            checked={checkbox2}
            onChange={event => setCheckbox2(event.target.checked)}
          />
          <span className="w-form-label">
            I want to share my knowledge and experiences by speaking in public events
          </span>
        </label>
        <label className="w-checkbox block-checkbox">
          <input
            type="checkbox"
            id="checkbox3"
            name="checkbox3"
            className="w-checkbox-input"
            checked={checkbox3}
            onChange={event => setCheckbox3(event.target.checked)}
          />
          <span className="w-form-label">
            I am an expert, I wish I can build a syllabus, open a class, and teach young professionals
          </span>
        </label>
        <div className="block-sidenote">
          <div>
            <strong className="inline-bold">
              Note: We can help build your credibility by exposing your reliability
            </strong>
          </div>
          <div>
            These new and junior professionals need us, please only tick those role that you think you can commit to.
            Cause we build our credibility by showcasing our reliability
          </div>
        </div>
        <input type="submit" value={btnText} className="button-primary w-button" disabled={btnDisabled} />
      </form>
      <div className="w-form-done" style={doneStyle}>
        <div>Thank you! Your submission has been received!</div>
      </div>
      <div className="w-form-fail" style={failStyle}>
        <div>Oops! Something went wrong while submitting the form.</div>
      </div>
    </div>
  );
};

export default BeMentorEmail;
