import React from "react";

import SEO from "../Seo";
import { EnrollmentEmail } from "../EmailForm";

import ImgIncubationProgram from "../../assets/Incubation-program.jpg";

const Syllabus = () => (
  <>
    <SEO
      title="Oaknut Incubation Program: Young Professional Development Program"
      description="Many job seekers complain that there is a lack of opportunities in the market, while businesses complain that there is a lack of qualified talents in the market. This phenomenon shows that there is a gap between graduates produced by the traditional educational institution and what the business needs. In the spirit to bridge the talent gap, we gather reliable professionals to guide upcoming graduates in a (more-less) 12-weeks professional development program called Oaknut Incubation Program."
      image={ImgIncubationProgram}
    />
    <div className="section-course-overview">
      <div className="wrapper w-clearfix">
        <div className="block-course-content">
          <div className="h2 h2-course-title">Oaknut Incubation Program: Young Professional Development Program</div>
          <div className="bodytext bodytext-course-description">
            &quot;Many job seekers complain that there is a lack of opportunities in the market, while businesses
            complain that there is a lack of qualified talents in the market. This phenomenon shows that there is a gap
            between graduates produced by the traditional educational institution and what the business needs. In the
            spirit to bridge the talent gap, we gather reliable professionals to guide upcoming graduates in a
            (more-less) 12-weeks professional development program called Oaknut Incubation Program.
            <br />
            <br />
            Oaknut incubation program is a professional development program specifically provided to last year&#x27;s
            university student lasted for around 12 weeks of mentorship, projects or internship, and in-class coaching.
            Successful candidates for the whole 12 weeks will be assigned to a mentor for their first 6 months working
            experience (optional) to support successful adaptation in the working world. The program structure completes
            participants with business and practical skills while also guiding them in finding the fitting industry and
            career they&#x27;d like to pursue. This program is provided as a pro-bono program by partners of Oaks
            Co.&quot;
            <br />
          </div>
          <div className="block-course-mentors">
            <div className="text-course-mentors-name">
              Dea Gendyna &amp; Rahmat Fajri - Yuniandari Hendryana
              <br />
            </div>
            <div className="bodytext">Managing Partner of Oaks Co &amp; Oaknut Partner and Mentor</div>
          </div>
          <div className="block-course-cta">
            <a href="#enroll-course" className="button-primary w-inline-block">
              <div>Enroll now</div>
            </a>
          </div>
          <div className="block-course-overview-description">
            <div className="h2 h2-course-content-title">Overview</div>
            <div className="bodytext bodytext-course-description">
              Completing this course will allow you to understand all basic business knowledge needed to play as a
              business professional. The program structure can be applied generally in every organization, whether it is
              NGO, state own enterprise, company, or startups, you will understand how to contribute as a business
              professional in a business environment.
              <br />
            </div>
            <div className="h2 h2-course-content-title">Program Design</div>
            <div className="bodytext bodytext-course-description">
              &quot;This program will be delivered in 35% in class, 50% experience-based learning, and 15% mentorship
              and career pathing
              <br />
              <br />
              It will be delivered in 12-13 weeks with following details:
              <br />
              1. Week 1-2 for self-awareness and setting up personal goal (career pathing)
              <br />
              2. Week 3-8 project and/or internship (experiential-based learning). A mentor will be assigned to each
              participant during the period of internship or project.
              <br />
              3. Week 9-12, intense in-class training
              <br />
              4. Week 13, final test and scoring
              <br />
              <br />
              Class will be delivered inside different universities within the city&quot;
              <br />
            </div>
            <div className="h2 h2-course-content-title">Certification</div>
            <div className="bodytext bodytext-course-description">
              Certification of completion will be provided upon the completion of the project or internship, with 95%
              total attendance
              <br />
            </div>
            <div className="h2 h2-course-content-title">Program Content</div>
            <div className="bodytext bodytext-course-description">
              The basics of the course cover
              <br />
              I. The Why: discover, reflect and realize our capacity and capability to win in the business field
              <br />
              1.1 Discover, reflect and realize our capacity and capability to win in the business field
              <br />
              1.2 Oaknut LEAD Framework
              <br />
              <br />
              II. Leading Ownself: Purpose and Motivation - Participants find the life that they can thrive in (at work
              that can lead to the general success of own life) so that they can be more guided and confident when
              deciding on taking up a job
              <br />
              2.1 Self-Awareness
              <br />
              2.2 External Awareness (Industry, organization, structure, and inside Tech)
              <br />
              2.3 Being in the business environment
              <br />
              2.4 Career path design
              <br />
              <br />
              III Leading Others
              <br />
              3.1 The growth
              <br />
              3.2 The vision
              <br />
              3.3 Making a movement
              <br />
              3.4 Organizational alignment
              <br />
              <br />
              IV. Influencing Business Transformation
              <br />
              4.1 Practical - componential intelligence (analytical and problem solving)
              <br />
              4.2 Practical - contextual intelligence (Data-driven thinking, motivation, conformity, heuristics)
              <br />
              4.3 Business Intelligence - Business core (business model, process, PLC, and project management)
              <br />
              4.4 Business Intelligence - Finance for newbie, HR for newbie, and basic data analysis (excel)
              <br />
              4.5 Business Intelligence - Organizational identity and architecture
              <br />
              4.6 Business Intelligence - Strategy, control, and decision making
              <br />
              4.7 Business Intelligence - Business interpersonal (networking and socio-cultural sensitivity)&quot;
              <br />
              <br />
            </div>
            <div className="h2 h2-course-content-title">Participant Profile</div>
            <div className="bodytext bodytext-course-description">
              1. Last Year University Students
              <br />
              2. University students passed 3rd Semester who have found their passion but find difficulty to develop
              business and practical skills
              <br />
            </div>
            <div className="h2 h2-course-content-title">Mentor Profile</div>
            <div className="bodytext bodytext-course-description">
              Dea Gendyna
              <br />
              Managing Partner of Oaks Co.
              <br />
              <br />
              &quot;Dea has worked as HR consultant, Finance and HR Leader in Egypt, Indonesia, Thailand, and Singapore
              for a couple of big names such as Mercer, world’s best HR Consulting firm; Traveloka, one of SEA’s biggest
              unicorns, and Style Theory, the first and biggest fashion rental subscription company in South East Asia.
              While acting as VP of People in one of the emerging startups under a Unicorn company, she is also managing
              partners, mentors, and educators of Oaks Co.
              <br />
              <br />
              In 2018, Dea has earned HR Transformation Certificate from Cornell University, USA. Prior to her
              enrollment in certification program with Cornell, she graduated with Magna Cum Laude honor (3,67 GPA) in
              economics, then she was trusted to lead in Finance and Governance area in a global social enterprise exist
              in over 127 countries and territories; as well as teaching 3 economics studies during her time in
              university: cost accounting, managerial accounting, and business process design.
              <br />
              <br />
              Rahmat Fajri
              <br />
              Oaknut Partner and Mentor
              <br />
              <br />
              Graduated from the best high school and Jakarta, and the best technology university in Indonesia, Fajri
              works as Management Consultant, People &amp; Organization at PricewaterhouseCoopers. Aside from working,
              Fajri has been a mentor for many university students as what keeps him in contact with his interest in
              leadership and human development.
              <br />
              <br />
              Yuniandari Hendryana
              <br />
              Oaknut Partner and Mentor
              <br />
              <br />
              &quot;With 7+ years of experience in a service role for a multinational company and start-up, Yuni brings
              a customer-focused and creative problem-solving approach to provide outstanding service. She possessed
              solid skill in client management across business and sector, demonstrating excellent communication across
              all levels, building and managing the team. Currently, Yuni is trusted as Head of Customer Success in one
              of the emerging recruitment companies in Indonesia.
              <br />
            </div>
            <div className="h2 h2-course-content-title">Investment</div>
            <div className="bodytext bodytext-course-description">
              IDR. 0 (for upcoming graduates)
              <br />
            </div>
            <div className="h2 h2-course-content-title">Starting dates and duration</div>
            <div className="bodytext bodytext-course-description">
              Depending on each city, 12 weeks.
              <br />
              For Jakarta: Mid Dec 2019 - Early Mar 2020
              <br />
            </div>
            <div className="h2 h2-course-content-title">Type and location</div>
            <div className="bodytext bodytext-course-description">
              35% in class, 50% experience-based learning, and 15% mentorship and career pathing, Variety of University
              <br />
            </div>
            <div className="h2 h2-course-content-title">Status</div>
            <div className="bodytext bodytext-course-description">
              (Class Size: Strictly max 30 people per class)
              <br />
            </div>
          </div>
        </div>
        <div className="block-course-content-card">
          <div className="block-course-card-images">
            <img src={ImgIncubationProgram} alt="" />
          </div>
          <div className="block-course-card-details">
            <div className="bodytext course-card-title">Course name</div>
            <div className="text-course-mentors-name">
              Oaknut Incubation Program: Young Professional Development Program
              <br />
            </div>
            <div className="bodytext course-card-title">Starting dates</div>
            <div className="text-course-mentors-name">
              Depending on each city, 12 weeks.
              <br />
              For Jakarta: Mid Dec 2019 - Early Mar 2020
              <br />
            </div>
            <div className="bodytext course-card-title">Type and location</div>
            <div className="text-course-mentors-name">
              35% in class, 50% experience-based learning, and 15% mentorship and career pathing, Variety of University
              <br />
            </div>
            <div className="block-primary-button-course">
              <a href="#enroll-course" className="button-primary button-primary-full w-inline-block">
                <div>Enroll now</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <EnrollmentEmail />
  </>
);

export default Syllabus;
