import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";

import SEO from "../Seo";
import { NeedSponsorEmail, NeedSpeakerEmail, BeMentorEmail } from "../EmailForm";
import { PreFooter } from "../Footer";

import ImgArrowTertiary from "../../assets/Arrow-Tertiary.svg";
import ImgOaktreeCareer from "../../assets/Oaktree---career.svg";
import ImgExploreIllustration from "../../assets/Explore-Illustration_1.svg";
import ImgLinkMatchIllustration from "../../assets/Link-match-illustration.svg";
import ImgInterviewIllustration from "../../assets/Interview--illustration.svg";
import ImgLearnIllustration from "../../assets/Learn-illustration.svg";
import ImgPodcast from "../../assets/Podcast.png";
import ImgPodcastSmall from "../../assets/Podcast-p-500.png";
import ImgEvents from "../../assets/Events.png";
import ImgEventsSmall from "../../assets/Events-p-500.png";
import ImgIGTV from "../../assets/IGTV.png";
import ImgIGTVSmall from "../../assets/IGTV-p-500.png";

const CareerCapital = ({ location }) => {
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    const urlQuery = queryString.parse(location.search);
    if (urlQuery.tab) {
      setActiveTab(urlQuery.tab);
    } else {
      setActiveTab("need-sponsor");
    }
  }, [location]);

  return (
    <>
      <SEO
        title="Oaktree - Career Capital Sponsorship"
        description="Accelerating your career in this world full of limitation and competition requires support from the outside"
      />
      <div className="section-hero">
        <div className="wrapper w-clearfix">
          <div className="block-heroimage responsive-image">
            <img src={ImgOaktreeCareer} alt="" />
          </div>
          <div className="block-herotext">
            <div className="h1">Career Sponsorship and Mentorship: The two powerful career accelerators</div>
            <div className="bodytext">
              Sometimes, accelerating your career in this world full of limitation and competition requires support from
              the outside
            </div>
            <div className="block-button">
              <a href="#oaktree-apply-mentor" className="button-primary w-inline-block">
                <div>Explore probono</div>
              </a>
              <a
                href="#oaktree-how-find-mentor"
                className="hyperlink-tertiary hyperlink-tertiary-with-badge w-inline-block"
              >
                <div className="hyperlink-text">Learn how it  works</div>
                <img src={ImgArrowTertiary} alt="" className="arrow-icon" />
              </a>
            </div>
          </div>
          <div className="block-heroimage">
            <img src={ImgOaktreeCareer} alt="" />
          </div>
        </div>
      </div>
      <div id="oaktree-how-find-mentor" className="section-how-it-works">
        <div className="wrapper w-clearfix">
          <div className="h2 h2-section-personas">How can I find a career mentor or sponsor?</div>
          <div className="bodytext bodytext-section-personas">
            We are humbled by everyone&#x27;s interest, however, with the limitation of our career mentor and sponsor,
            it may take a bit patience and a slight competition to pass through our 4 steps of connecting you and the
            career sponsor.
          </div>
          <div className="block-four-row">
            <img src={ImgExploreIllustration} alt="" className="three-row-images" />
            <div className="four-row-title">Screen</div>
            <div className="bodytext bodytext-section-four-row">
              We are humbled by your interest, however, we screen people carefully before we hand over your profile to
              our career mentors and sponsors.
            </div>
          </div>
          <div className="block-four-row">
            <img src={ImgLinkMatchIllustration} alt="" className="three-row-images" />
            <div className="four-row-title">Match</div>
            <div className="bodytext bodytext-section-four-row">
              Our mentors and sponsors are active professionals thus they may need to choose among many requests. Check
              out&nbsp;
              <a href="#tips" className="inline-link">
                these tips
              </a>
              &nbsp;to make you stand out in front of them.
            </div>
          </div>
          <div className="block-four-row">
            <img src={ImgInterviewIllustration} alt="" className="three-row-images" />
            <div className="four-row-title">Schedule</div>
            <div className="bodytext bodytext-section-four-row">
              Our team will connect and introduce you to your career sponsor. Your career sponsor will let you know
              his/her time availability to arrange a meeting.&nbsp;
            </div>
          </div>
          <div className="block-four-row">
            <img src={ImgLearnIllustration} alt="" className="three-row-images" />
            <div className="four-row-title">Support</div>
            <div className="bodytext bodytext-section-four-row">
              Our support will meet you at the defined time and place, ask as many as possible to satisfy your searching
              on words of wisdoms and career tips in the defimed area
            </div>
          </div>
        </div>
      </div>
      <div id="oaktree-apply-mentor" className="section-career-apply">
        <div className="wrapper">
          <div className="h2 h2-section-personas">How can I find a career mentor or sponsor?</div>
          <div className="bodytext align-center">
            We are humbled by everyone&#x27;s interest, however, with the limitation of our career mentor and sponsor,
            it may take a bit patience and a slight competition to pass through our 4 steps of connecting you and the
            career sponsor.
          </div>
          <div data-duration-in="300" data-duration-out="100" className="multi-tab w-tabs">
            <div className="multi-tab-menu w-tab-menu">
              <a
                href="#/"
                data-w-tab="Need-sponsor"
                className={`tab-link w-inline-block w-tab-link${activeTab === "need-sponsor" ? " w--current" : ""}`}
              >
                <div>Need sponsor or mentor ?</div>
              </a>
              <a
                href="#/"
                data-w-tab="Need-speaker"
                className={`tab-link w-inline-block w-tab-link${activeTab === "need-speaker" ? " w--current" : ""}`}
              >
                <div>Need speaker ?</div>
              </a>
              <a
                href="#/"
                data-w-tab="Be-mentor"
                className={`tab-link w-inline-block w-tab-link${activeTab === "be-mentor" ? " w--current" : ""}`}
              >
                <div>Be our mentor or speaker</div>
              </a>
            </div>
            <div className="multi-tab-content w-tab-content">
              <div
                data-w-tab="Need-sponsor"
                className={`tab-panel w-tab-pane${activeTab === "need-sponsor" ? " w--tab-active" : ""}`}
              >
                <NeedSponsorEmail />
              </div>
              <div
                data-w-tab="Need-speaker"
                className={`tab-panel w-tab-pane${activeTab === "need-speaker" ? " w--tab-active" : ""}`}
              >
                <NeedSpeakerEmail />
              </div>
              <div
                data-w-tab="Be-mentor"
                className={`tab-panel w-tab-pane${activeTab === "be-mentor" ? " w--tab-active" : ""}`}
              >
                <BeMentorEmail />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="oaktree-benefit" className="section-benefit-knowledge">
        <div className="wrapper align-center">
          <div className="h2 h2-section-personas">Enjoy the benefit of being in a knowledge-inclusive society</div>
          <div className="bodytext align-center">
            From career hour in our social media channel, experience sharing through podcast, or just a knowledge
            sharing through our community event, our probono activities are designed to provide insights and to connect
            with the diligent knowledge seekers. Check this page regularly, cause even though our events are mostly
            offered for free, they are often opened for limited seat or time.
          </div>
          <div className="block-benefit-card w-clearfix">
            <a
              href="https://open.spotify.com/show/65GgL8A4JOqYMXFXCguTyY?si=RhI3qsuiRVe3loveBcLctQ"
              target="_blank"
              rel="noopener noreferrer"
              className="block-course-card w-inline-block"
            >
              <img
                src={ImgPodcast}
                srcSet={`${ImgPodcastSmall} 500w, ${ImgPodcast} 792w`}
                sizes="(max-width: 479px) 90vw, (max-width: 767px) 94vw, (max-width: 991px) 46vw, 342px"
                alt=""
              />
            </a>
            <a
              href="https://www.linkedin.com/company/oaksociety/"
              target="_blank"
              rel="noopener noreferrer"
              className="block-course-card w-inline-block"
            >
              <img
                src={ImgEvents}
                srcSet={`${ImgEventsSmall} 500w, ${ImgEvents} 792w`}
                sizes="(max-width: 479px) 90vw, (max-width: 767px) 94vw, (max-width: 991px) 46vw, 342px"
                alt=""
              />
            </a>
            <a
              href="https://instagram.com/oaks.co?igshid=1nmdt3jsww9hv"
              target="_blank"
              rel="noopener noreferrer"
              className="block-course-card w-inline-block"
            >
              <img
                src={ImgIGTV}
                srcSet={`${ImgIGTVSmall} 500w, ${ImgIGTV} 792w`}
                sizes="(max-width: 479px) 90vw, (max-width: 767px) 94vw, (max-width: 991px) 46vw, 342px"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
      <PreFooter />
    </>
  );
};

CareerCapital.propTypes = { location: PropTypes.instanceOf(Object).isRequired };

export default CareerCapital;
