import React, { useState } from "react";
import axios from "axios";

import ImgArrowTertiary from "../../assets/Arrow-Tertiary.svg";

const OaknutEmail = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [program, setProgram] = useState("");
  const [faculty, setFaculty] = useState("");
  const [cvFile, setCvFile] = useState(null);
  const [cvKey, setCvKey] = useState(Date.now() + 1);
  const [coverLetterFile, setCoverLetterFile] = useState(null);
  const [coverKey, setCoverKey] = useState(Date.now() + 2);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [btnText, setBtnText] = useState("Submit");
  const [failStyle, setFailStyle] = useState({ display: "none" });
  const [doneStyle, setDoneStyle] = useState({ display: "none", marginTop: 10 });

  const clearForm = () => {
    setFullName("");
    setEmail("");
    setPhone("");
    setProgram("");
    setFaculty("");
    setCvFile(null);
    setCvKey(Date.now() + 1);
    setCoverLetterFile(null);
    setCoverKey(Date.now() + 2);
    setBtnDisabled(false);
    setBtnText("Submit");
  };

  const onSubmit = event => {
    setFailStyle({ display: "none" });
    setDoneStyle({ display: "none", marginTop: 10 });
    setBtnText("Please wait....");
    setBtnDisabled(true);

    const MailAPI = `${process.env.REACT_APP_API_ENDPOINT}/api/inbox`;
    const axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const formData = new FormData();
    formData.append("Full-name", fullName);
    formData.append("Email-address", email);
    formData.append("Phone-number", phone);
    formData.append("How-did-you-find-our-program?", program);
    formData.append("Faculty", faculty);
    formData.append("CV-File", cvFile);
    if (coverLetterFile) {
      formData.append("Cover-Letter-File", coverLetterFile);
    }
    formData.append("Mail-From", process.env.REACT_APP_WEBSITE_URL);
    formData.append("Mail-Reply", email);
    formData.append("Mail-Form", "Oaknut Form");
    formData.append("Mail-Category", "oaknut-contact-form");
    formData.append("Mail-Subject", "Oaknut Form Submitted");
    formData.append("Mail-Title", `${fullName} - ${email}`);
    formData.append("Mail-Excerpt", program);
    formData.append("Mail-Content", faculty);

    axios
      .post(MailAPI, formData, axiosConfig)
      .then(res => {
        clearForm();
        if (res.data.code === 200) {
          setDoneStyle({ display: "block", marginTop: 10 });
        } else {
          setFailStyle({ display: "block" });
        }
      })
      .catch(err => {
        setFailStyle({ display: "block" });
        setBtnText("Submit");
        setBtnDisabled(false);
        console.log(err);
      });

    event.preventDefault();
  };

  return (
    <div id="oaknut-contact" className="section-oaknut-contact">
      <div className="wrapper w-clearfix">
        <div className="block-oaknut-contact-description">
          <div className="h3 h3-oaknut-contact-description">
            Join the Oaknut Incubation and start your amazing career with full preparation!
          </div>
          <div className="bodytext">We design the program to connect and get you used to the business world early.</div>
          <div className="block-contact-form-description w-clearfix">
            <div className="block-contact-indicator" />
            <div className="block-contact-description">
              <div className="block-contact-title">Experience the working world early</div>
              <div>
                Consultants, unicorn&#x27;s amazing people, cum laude graduates, best uni&#x27;s certificate holders,
                they can be your coach and mentor to start your career
              </div>
            </div>
          </div>
          <div className="block-contact-form-description w-clearfix">
            <div className="block-contact-indicator block-content-indicator-secondary" />
            <div className="block-contact-description">
              <div className="block-contact-title">Learn from experts</div>
              <div>
                Not only you get coached by proven professionals, but also learning from the inside-process and job of a
                company
              </div>
            </div>
          </div>
          <div className="block-contact-form-description w-clearfix">
            <div className="block-contact-indicator block-content-indicator-tertiary" />
            <div className="block-contact-description">
              <div className="block-contact-title">Get certified and referred to companies</div>
              <div className="badge-large">
                <div className="text-block">Exclusive</div>
              </div>
              <div>
                Only those who complete our final coursework will get certified, and if you are, we will help connect
                you to promising companies.
              </div>
            </div>
          </div>
        </div>
        <div className="block-oaknut-contact">
          <div className="block-oaknut-contact-form w-form">
            <form
              id="email-form"
              name="email-form"
              data-name="Email Form"
              action="submitform"
              onSubmit={onSubmit}
              encType="multipart/form-data"
            >
              <label htmlFor="fullName" className="form-label">
                Full name
              </label>
              <input
                type="text"
                className="form-text-field w-input"
                maxLength="256"
                name="fullName"
                placeholder="Type your full name"
                id="fullName"
                value={fullName}
                onChange={event => setFullName(event.target.value)}
                required
              />
              <div className="block-form-two-row-oaknut">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-text-field w-input"
                  maxLength="256"
                  name="email"
                  placeholder="Type your email"
                  id="email"
                  value={email}
                  onChange={event => setEmail(event.target.value)}
                  required
                />
              </div>
              <div className="block-form-two-row-oaknut block-form-two-row-oaknut-children">
                <label htmlFor="phone" className="form-label">
                  Phone
                </label>
                <input
                  type="text"
                  className="form-text-field w-input"
                  maxLength="256"
                  name="phone"
                  placeholder="Valid phone number"
                  id="phone"
                  value={phone}
                  onChange={event => setPhone(event.target.value)}
                  required
                />
              </div>
              <div className="block-form-two-row-oaknut">
                <label htmlFor="program" className="form-label">
                  How did you find our program?
                </label>
                <select
                  id="program"
                  name="program"
                  className="form-text-field w-select"
                  value={program}
                  onChange={event => setProgram(event.target.value)}
                >
                  <option value="">Select one...</option>
                  <option value="University">University</option>
                  <option value="Inside-university">Inside-university</option>
                  <option value="Outside-university">Outside-university</option>
                  <option value="Social-media">Social-media</option>
                  <option value="Word of mouth">Word of mouth</option>
                </select>
              </div>
              <div className="block-form-two-row-oaknut block-form-two-row-oaknut-children">
                <label htmlFor="faculty" className="form-label">
                  Faculty
                </label>
                <select
                  id="faculty"
                  name="faculty"
                  required
                  className="form-text-field w-select"
                  value={faculty}
                  onChange={event => setFaculty(event.target.value)}
                >
                  <option value="">Select one...</option>
                  <option value="Technology Information">Technology Information</option>
                  <option value="Social">Social</option>
                  <option value="Economy">Economy</option>
                </select>
              </div>
              <label htmlFor="cvFile" className="form-label">
                Upload CV
              </label>
              <input
                type="file"
                name="cvFile"
                key={cvKey}
                className="form-text-field w-input"
                onChange={event => setCvFile(event.target.files[0])}
                accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                placeholder="pdf or .doc - Max 1.5mb"
                required
              />
              <label htmlFor="coverLetterFile" className="form-label">
                Cover letter (Optional)
              </label>
              <input
                type="file"
                name="coverLetterFile"
                key={coverKey}
                className="form-text-field w-input"
                onChange={event => setCoverLetterFile(event.target.files[0])}
                accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                placeholder="pdf or .doc - Max 1.5mb"
              />
              <input type="submit" value={btnText} className="button-primary w-button" disabled={btnDisabled} />
              <a href="#oaknut-suggest-city" className="hyperlink-tertiary w-inline-block">
                <div className="hyperlink-text">Let me suggest my university</div>
                <img src={ImgArrowTertiary} alt="" className="arrow-icon" />
              </a>
            </form>
            <div className="w-form-done" style={doneStyle}>
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div className="w-form-fail" style={failStyle}>
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OaknutEmail;
