export const LANDING = "/";
export const ABOUT = "/about";
export const OAKNUT = "/oaknut";
export const OAKTREE = "/oaktree";
export const OAKWOOD = "/oakwood";
export const CAREER_CAPITAL = "/career-capital";
export const SCHOOL_FOR_PROFESSIONAL = "/school-for-professional";
export const HR_STRATEGY = "/hr-strategy";
export const TALENT_SOLUTIONS = "/talent-solutions";
export const SYLLABUS = "/syllabus";
export const UNIVERSITY = "/university";
export const COURSES = "/courses/:id/:slug";
