import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import NotMatchPage from "../NotMatch";
import Navigation from "../Navigation";
import Footer from "../Footer";
import LandingPage from "../Landing";
import AboutPage from "../About";
import OaknutPage from "../Oaknut";
import OaktreePage from "../Oaktree";
import SchoolForProfessionalPage from "../SchoolForProfessional";
import CareerCapitalPage from "../CareerCapital";
import SyllabusPage from "../Syllabus";
import CoursesPage from "../Courses";
import OakwoodPage from "../Oakwood";
import TalentSolutionPage from "../TalentSolution";
import UniversityPage from "../University";
import HRStrategyPage from "../HrStrategy";

import * as ROUTES from "../../constants/routes";

function App() {
  return (
    <Router forceRefresh>
      <Navigation />
      <Switch>
        <Route exact path={ROUTES.LANDING} component={LandingPage} />
        <Route path={ROUTES.ABOUT} component={AboutPage} />
        <Route path={ROUTES.OAKNUT} component={OaknutPage} />
        <Route path={ROUTES.OAKTREE} component={OaktreePage} />
        <Route path={ROUTES.SCHOOL_FOR_PROFESSIONAL} component={SchoolForProfessionalPage} />
        <Route path={ROUTES.CAREER_CAPITAL} component={CareerCapitalPage} />
        <Route path={ROUTES.SYLLABUS} component={SyllabusPage} />
        <Route path={ROUTES.COURSES} component={CoursesPage} />
        <Route path={ROUTES.OAKWOOD} component={OakwoodPage} />
        <Route path={ROUTES.TALENT_SOLUTIONS} component={TalentSolutionPage} />
        <Route path={ROUTES.UNIVERSITY} component={UniversityPage} />
        <Route path={ROUTES.HR_STRATEGY} component={HRStrategyPage} />
        <Route exact component={NotMatchPage} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
