import React from "react";
import { Link } from "react-router-dom";

import SEO from "../Seo";
import { GeneralEmail } from "../EmailForm";
import { PreFooter } from "../Footer";

import * as ROUTES from "../../constants/routes";

import ImgArrowTertiary from "../../assets/Arrow-Tertiary.svg";
import ImgOaknut from "../../assets/Oaknut.svg";
import ImgOaktree from "../../assets/Oaktree.svg";
import ImgAboutIllustration from "../../assets/About-Illustration.svg";
import ImgGradesIllustration from "../../assets/Grades-Illustration.svg";
import ImgProfessionalIllustration from "../../assets/Professional-illustration.svg";
import ImgStudentIllustration from "../../assets/Student-Illustration.svg";
import ImgCredibilityIllustration from "../../assets/Credibility-Illustration.svg";
import ImgCoachingIllustration from "../../assets/Coaching-Illustration.svg";
import ImgTeamWorkIllustration from "../../assets/Team-work-Illustration.svg";
import ImgUniversityIllustration from "../../assets/University-Illustration.svg";
import ImgCompanyOrgIllustration from "../../assets/Company-Org-Illustration.svg";

const About = () => (
  <>
    <SEO
      title="About - Oaks history"
      description="We dream of *a knowledge inclusive society* that is capable to grow everyone&#x27;s career capital."
    />
    <div className="section-single-hero">
      <div className="wrapper">
        <div className="h1">Group of remarkable humans</div>
        <div className="bodytext">
          Oaks Co. provides all around support to set an amazing win-win business relationship between employees and
          employers
        </div>
        <div className="block-button-single-hero">
          <a href="#general-contact" className="button-primary w-inline-block">
            <div>Contact us</div>
          </a>
          <a href="#faq" className="hyperlink-tertiary w-inline-block">
            <div className="hyperlink-text">Read Our FAQ</div>
            <img src={ImgArrowTertiary} alt="" className="arrow-icon" />
          </a>
        </div>
        <div className="block-single-hero-image">
          <img src={ImgAboutIllustration} alt="" />
        </div>
      </div>
    </div>
    <div className="section-about-description">
      <div className="wrapper-medium">
        <div className="h2">Oaks story</div>
        <div className="bodytext bodytext-section-about-description">
          When we imagine a life where everybody can get full access to knowledge, we can also see the possibility when
          everybody can have the same access to opportunity and advancement. That is why we believe. knowledge and
          information have the power to transform economies and societies. They have a significant impact on people’s
          lives.
          <br />
          <br />
          Since then, Oaks co. was founded. Our team consists of experts who have hearts beating hard for wider access
          for knowledge and experiences. We dream of *a knowledge inclusive society* that is capable to grow
          everyone&#x27;s career capital.
          <br />
          <br />
          By focusing on the development of everyone’s career capital – the skills, connections, and credentials that
          allow a person to have an impact in their job, we can set an amazing win-win business relationship between
          employees and employers that opens the possibility for business and talents to grow in sync.
          <br />
          <br />
          We, Oaks Co., are the first all-around career capital firm in Indonesia.
        </div>
      </div>
    </div>
    <div id="about-improve-social" className="section-improve-socializing">
      <div className="wrapper w-clearfix">
        <div className="h2 h2-section-personas">
          Improving society through knowledge sharing, leadership building, and organization transformation
        </div>
        <div className="bodytext bodytext-section-personas">
          By building a collaborative ecosystem that revolves around developing people&#x27;s competencies and
          personality, we can increase absorption of employment, restore morals, and improve everyone&#x27;s quality of
          life.
        </div>
        <div className="block-two-row">
          <img src={ImgGradesIllustration} alt="" className="block-two-row-image" />
          <div className="h3 block-two-row-title">
            <strong className="inline-bold">Students</strong>
            {" nurtured by"}
            <strong className="inline-bold"> University</strong>
          </div>
          <div className="bodytext">
            University is often considered as the leader of teaching activities, the production of highly skilled
            manpower, and the development of youth&#x27;s personality.
            {" That is why, "}
            <strong>Upcoming Graduates</strong>
            {" and "}
            <strong>University</strong>
            {" are the 2 (two) among 5 of our impact segments."}
          </div>
          <div className="block-button">
            <a href="#about-youth-development" className="button-primary w-inline-block">
              <div>Student and Upcoming Graduate</div>
            </a>
            <a
              href="#about-organization-development"
              className="button-primary button-tertiary button-second-line w-inline-block"
            >
              <div>University</div>
            </a>
          </div>
        </div>
        <div className="block-two-row">
          <img src={ImgProfessionalIllustration} alt="" className="block-two-row-image" />
          <div className="h3 block-two-row-title">
            <strong className="inline-bold">Professionals</strong>
            {" nurtured by"}
            <br />
            <strong className="inline-bold">Leaders</strong>
            {" and "}
            <strong className="inline-bold">Companies</strong>
          </div>
          <div className="bodytext">
            We believe in the significant role of leaders and all business, as employers, to influence behavioral
            transformation in our society. That is why, &quot;professionals&quot;, &quot;leaders&quot;, and
            &quot;companies&quot; are the other 3 segments that we are seeking to collaborate with.
          </div>
          <div className="block-button">
            <a href="#about-leadership-development" className="button-primary w-inline-block">
              <div>Leaders and Executive</div>
            </a>
            <a
              href="#about-organization-development"
              className="button-primary button-tertiary button-second-line w-inline-block"
            >
              <div>Companies</div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div id="about-youth-development" className="section-zebra-a">
      <div className="wrapper w-clearfix">
        <div className="h2 align-center h2-zebra-a">Youth Development</div>
        <div className="block-zebra-a-image">
          <img src={ImgStudentIllustration} alt="" />
        </div>
        <div className="block-zebra-a-text">
          <img src={ImgOaknut} alt="" className="logo-product-zebra" />
          <div className="h3 h3-zebra">
            <em>
              “I’m a student, please help us polish
              <br />
              our skills!”
            </em>
          </div>
          <div className="bodytext">
            Whether you need an expert to speak in your university event or need a professional development program
            before your graduation, we are here to help
          </div>
          <div className="block-button">
            <Link to={ROUTES.SYLLABUS} className="button-primary w-inline-block">
              <div className="badge-medium badge-medium-custom-a">
                <div>Free</div>
              </div>
              <div>Apply to our Professional Development Program</div>
            </Link>
            <Link
              to={ROUTES.COURSES.replace(":slug", "job-application-preparation-guide")}
              className="button-primary button-secondary button-secondary-with-badge w-inline-block"
            >
              <div>Explore our other support</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
    <div id="about-professional" className="section-zebra-b">
      <div className="wrapper w-clearfix">
        <div className="block-zebra-b-image responsive-image">
          <img src={ImgCredibilityIllustration} alt="" />
        </div>
        <div className="block-zebra-b-text">
          <img src={ImgOaktree} alt="" className="logo-product-zebra" />
          <div className="h3 h3-zebra">
            <em>“I’m a professional, but I need help to build credibility in the market”</em>
          </div>
          <div className="bodytext">
            Whether you need a mentor, a speaker for your event, a professional network to build your visibility in the
            market, or just an expert that you can have casual chat to learn from, we may be able to help.
          </div>
          <div className="block-button">
            <a href={`${ROUTES.SCHOOL_FOR_PROFESSIONAL}#oaktree-courses`} className="button-primary w-inline-block">
              <div>Explore courses and classes</div>
            </a>
            <Link
              to={ROUTES.CAREER_CAPITAL}
              className="button-primary button-secondary button-secondary-with-badge w-inline-block"
            >
              <div className="badge-medium badge-medium-custom-b">
                <div>Free</div>
              </div>
              <div>Explore our pro-bono supports</div>
            </Link>
          </div>
        </div>
        <div className="block-zebra-b-image">
          <img src={ImgCredibilityIllustration} alt="" />
        </div>
      </div>
    </div>
    <div id="about-leadership-development" className="section-improve-socializing">
      <div className="wrapper w-clearfix">
        <div className="h2 h2-section-personas">Leadership Development</div>
        <div className="bodytext bodytext-section-personas">
          We believe that leadership shapes the moral and success direction of our youth as well as the organizations
        </div>
        <div className="block-two-row">
          <img src={ImgCoachingIllustration} alt="" className="block-two-row-image" />
          <div className="h3 block-two-row-title">Executive Coaching Program</div>
          <div className="bodytext">
            The Executive Coaching Program is designed around a framework of core and self-organized learning, supported
            by individual coaching, that enables participants to build a personalized leadership development program
            structured around a clear set of criteria
          </div>
          <div className="block-button">
            <a href="#general-contact" className="button-primary w-inline-block">
              <div>Contact Us</div>
            </a>
          </div>
        </div>
        <div className="block-two-row">
          <img src={ImgTeamWorkIllustration} alt="" className="block-two-row-image" />
          <div className="h3 block-two-row-title">First-time Manager</div>
          <div className="bodytext">
            The First Time Manager Development Program is designed to equip new leaders with a set of basic management
            habits that can naturally adjust them to the new responsibility of managing others
          </div>
          <div className="block-button">
            <a href="#general-contact" className="button-primary w-inline-block">
              <div>Contact Us</div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div id="about-organization-development" className="section-improve-socializing">
      <div className="wrapper w-clearfix">
        <div className="h2 h2-section-personas">Organization Development and Transformation</div>
        <div className="bodytext bodytext-section-personas">
          We strive to forge collaboration with universities and businesses to form an ecosystem that encourages the
          success and impact of our younger generation
        </div>
        <div className="block-two-row">
          <img src={ImgUniversityIllustration} alt="" className="block-two-row-image" />
          <div className="h3 block-two-row-title">University: enterprise-based adaptation</div>
          <div className="bodytext">
            We can bring professionals and organizations to help adapt your practicum or other teaching activities to
            the needs of the working world
          </div>
          <div className="block-button">
            <Link to={ROUTES.UNIVERSITY} className="button-primary w-inline-block">
              <div>University Transformation</div>
            </Link>
          </div>
        </div>
        <div className="block-two-row">
          <img src={ImgCompanyOrgIllustration} alt="" className="block-two-row-image" />
          <div className="h3 block-two-row-title">Company: organization development</div>
          <div className="bodytext">
            Whether to set up an HR architecture (HR Coach &amp; Partner) or to assist the implementation of HR
            activities (Talent Solutions), we are eiger to be a part of your company&#x27;s set up and transformation
          </div>
          <div className="block-button">
            <Link to={ROUTES.HR_STRATEGY} className="button-primary w-inline-block">
              <div>HR Strategic Coach and Partner</div>
            </Link>
            <Link
              to={ROUTES.TALENT_SOLUTIONS}
              className="button-primary button-secondary button-second-line w-inline-block"
            >
              <div>Talent solution</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
    <div id="faq" className="section-faq">
      <div className="wrapper-medium">
        <div className="h2 h2-section-personas">Explore Frequently Asked Questions</div>
        <div className="bodytext bodytext-section-personas">
          Some popular questions we gather to help you understand us better
        </div>
      </div>
      <div className="wrapper-medium align-left">
        <div className="block-faq-qna">
          <div className="h5">There are programs labeled as &quot;free&quot;. What does it mean?</div>
          <div className="bodytext bodytext-faq-qna">
            The program labeled as free means that we don&#x27;t collect payment when delivering the service. However,
            there may be an administration cost that occurs. but don&#x27;t worry, it should be very affordable.
          </div>
        </div>
        <div className="block-faq-qna">
          <div className="h5">I saw some programs are labeled as your Probono service? What is a Probono service?</div>
          <div className="bodytext bodytext-faq-qna">
            Probono comes from a Latin word &quot;pro bono publico&quot; which means ‘for the public good’. Any programs
            included in our probono means our works undertaken without charge, or very low charge (administration fee
            only)
          </div>
        </div>
        <div className="block-faq-qna">
          <div className="h5">
            I am interested to open a class in your company, will it be a full-time job? how does this work?
          </div>
          <div className="bodytext bodytext-faq-qna">
            Being a faculty author or a teacher for Oaks Co. is a part-time job. The class schedule is set based on the
            teachers&#x27; weekly time availability. However, there are certain qualification process and training that
            you have to follow and pass before opening a class with us. Please contact our team for more information
          </div>
        </div>
        <div className="block-faq-qna">
          <div className="h5">
            I can&#x27;t see the program content (syllabus) in the program I&#x27;m looking,
            <br />
            how can I get more information?
          </div>
          <div className="bodytext bodytext-faq-qna">
            If you need more information, feel free to contact our team through WhatsApp or the question form below.
            They will contact you and explain to you more
          </div>
        </div>
      </div>
    </div>
    <GeneralEmail />
    <PreFooter />
  </>
);

export default About;
