import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-awesome-modal";

import SEO from "../Seo";
import { OaknutEmail, UniversityEmail } from "../EmailForm";
import { PreFooter } from "../Footer";

import * as ROUTES from "../../constants/routes";

import ImgHeroIllustration from "../../assets/Oaknut-hero-illustration.svg";
import ImgArrowTertiary from "../../assets/Arrow-Tertiary.svg";
import ImgCoaches from "../../assets/Coaches.svg";
import ImgUnique from "../../assets/Unique.svg";
import ImgCollab from "../../assets/Collab.svg";
import ImgApplyIllustration from "../../assets/1.-Apply-illustration.svg";
import ImgInterviewIllustration from "../../assets/2.-Interview--illustration.svg";
import ImgLearnOaknutIllustration from "../../assets/3.-Learn-oaknut-illustration.svg";
import ImgLinkMatchIllustration from "../../assets/4.-Link-match-illustration.svg";

const Oaknut = () => {
  const [showModal, setShowModal] = useState(false);

  const onToggleModal = flag => {
    setShowModal(flag);
  };

  return (
    <>
      <SEO
        title="Oaknut - Upcoming Graduates"
        description="We understand that not all things that the company needs have been covered by your degree. This inspires us to help bridging you coming to the working world, *for free*"
      />
      <div className="section-hero">
        <div className="wrapper w-clearfix">
          <div className="block-heroimage responsive-image">
            <img src={ImgHeroIllustration} alt="" />
          </div>
          <div className="block-herotext">
            <div className="h1">Do you have what it takes for companies to hire you?</div>
            <div className="bodytext">
              We understand that not all things that the company needs have been covered by your degree. This inspires
              us to help bridging you coming to the working world, *for free*
            </div>
            <div className="block-button">
              <a href="#oaknut-contact" className="button-primary w-inline-block">
                <div className="badge-medium">
                  <div>Free</div>
                </div>
                <div>Register now</div>
              </a>
              <a
                href="#oaknut-how-it-works"
                className="hyperlink-tertiary hyperlink-tertiary-with-badge w-inline-block"
              >
                <div className="hyperlink-text">Learn how it  works</div>
                <img src={ImgArrowTertiary} alt="" className="arrow-icon" />
              </a>
            </div>
          </div>
          <div className="block-heroimage">
            <img src={ImgHeroIllustration} alt="" />
          </div>
        </div>
      </div>
      <div id="oaknut-career-syllabus" className="section-oaknut-career">
        <div className="wrapper w-clearfix">
          <div className="h2 h2-section-personas">Let&#x27;s prepare the start of your career together</div>
          <div className="bodytext">
            If you are an upcoming graduate with big dream to impact, but is
            <br />
            confused with how to start your career, let us help you!
          </div>
          <div className="oaknut-syllabus">
            {"Check the Syllabus of our Oaknut Professional Development Program, "}
            <Link to={ROUTES.SYLLABUS} className="oaknut-syllabus-link">
              See details here
            </Link>
          </div>
          <div className="block-three-row">
            <img src={ImgCoaches} alt="" className="three-row-images" />
            <div className="h4 h4-three-row-align-left">Amazing coaches</div>
            <div className="bodytext bodytext-three-row-align-left">
              You can have consultants, unicorns&#x27; leaders, multi-national experts, cum laude graduates, and
              world&#x27;s best university certificate holders as your coaches
            </div>
          </div>
          <div className="block-three-row">
            <img src={ImgUnique} alt="" className="three-row-images" />
            <div className="h4 h4-three-row-align-left">Unique experience</div>
            <div className="bodytext bodytext-three-row-align-left">
              We combine in-class teaching and cognitive learning added with secret wow factors that keep you inspired
              and excited throughout the program.
            </div>
          </div>
          <div className="block-three-row">
            <img src={ImgCollab} alt="" className="three-row-images" />
            <div className="h4 h4-three-row-align-left">Collaborative effort</div>
            <div className="bodytext bodytext-three-row-align-left">
              To ensure the reliability of Oaknut as a bridging program, Oaknut incubation is a combined effort between
              young communities, companies, and people from universities.
            </div>
          </div>
        </div>
      </div>
      <div id="oaknut-how-it-works" className="section-how-it-works">
        <div className="wrapper w-clearfix">
          <div className="h2 h2-section-personas">Embark your career journey with Oaknut in 4 steps</div>
          <div className="bodytext bodytext-section-personas">
            To make sure quality of our development program, we select and incubate only 30 people per city and batch
            for 3 months. But don&#x27;t worry, we open the program 3-4 times a year.
          </div>
          <div className="block-four-row">
            <img src={ImgApplyIllustration} alt="" className="three-row-images" />
            <div className="four-row-title">Apply</div>
            <div className="bodytext bodytext-section-four-row">
              Graduated in around 6 months, haven&#x27;t had your first job?&nbsp;
              <a href="#/" onClick={() => onToggleModal(true)} className="inline-link inline-tnc">
                Check out our T&amp;C before you apply.
              </a>
            </div>
          </div>
          <div className="block-four-row">
            <img src={ImgInterviewIllustration} alt="" className="three-row-images" />
            <div className="four-row-title">Interview</div>
            <div className="bodytext bodytext-section-four-row">
              {"If you are a successful candidate, we will announce below the place and time for your "}
              <a href="#/" className="inline-link">
                interview schedule.
              </a>
            </div>
          </div>
          <div className="block-four-row">
            <img src={ImgLearnOaknutIllustration} alt="" className="three-row-images" />
            <div className="four-row-title">Learn</div>
            <div className="bodytext bodytext-section-four-row">
              If you pass the interview, register yourself in order to join Oaknut&#x27;s 3 months rollercoaster
              journey.
            </div>
          </div>
          <div className="block-four-row">
            <img src={ImgLinkMatchIllustration} alt="" className="three-row-images" />
            <div className="four-row-title">Match</div>
            <div className="bodytext bodytext-section-four-row">
              {"Get certified, and start applying to either our partner companies or others as you like! "}
              <a href="#/" className="inline-link">
                Successful incubated talents.
              </a>
            </div>
          </div>
        </div>
      </div>
      <OaknutEmail />
      <UniversityEmail />
      <PreFooter />
      <Modal visible={showModal} width="950" height="300" effect="fadeInDown" onClickAway={() => onToggleModal(false)}>
        <div className="block-overlay-100 block-overlay-popup-form">
          <div className="block-popup block-popup-hr" style={{ width: "100%" }}>
            <div className="popup-header">
              <div className="h3">Terms and conditions</div>
            </div>
            <div className="popup-description">
              <div className="pop-up-title">Have you read our terms and condition, before applying?</div>
              <div className="bodytext">
                1) The professional development program (Oaknut incubation program) will take around 3 months to
                complete, with additional 6-months free assistance (buddy) from our coaches after the 3-months
                incubation program, as our ongoing support to help you adapt to your first and new workplace.
                <br />
                &zwj;
                <br />
                Before you apply, we hope that you can commit to the 3-months learning period. Otherwise, we hope that
                you can let others have chance to get the learning.
                <br />
                &zwj;
                <br />
                2) In case you pass the screening process, our team will contact you for an interview. Upon the
                interview, you may be requested for IDR 100k as an interview fee. Invoice is available upon request.
                <br />
                &zwj;
                <br />
                3) We understand that it can be hard to get excited about dedicating yourself to something free. That is
                why, you may be required to put IDR. 750k deposit to start and continue your program. Don&#x27;t worry,
                we will give back your deposit after you complete the 3-months development program
                <br />
                &zwj;
              </div>
              <a
                href="#/"
                className="button-primary button-white button-popup-tnc w-inline-block"
                onClick={() => onToggleModal(false)}
              >
                <div>Got it and close pop up</div>
              </a>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Oaknut;
