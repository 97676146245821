import React from "react";
import { Link } from "react-router-dom";

import SEO from "../Seo";
import { GeneralEmail } from "../EmailForm";
import { PreFooter } from "../Footer";

import * as ROUTES from "../../constants/routes";

import ImgArrowTertiary from "../../assets/Arrow-Tertiary.svg";
import ImgOaktreeSchool from "../../assets/Oaktree---school-professional---illsutration---Medium.svg";
import ImgOaktreeCareer from "../../assets/Oaktree---career---medium.svg";
import ImgCostIllustration from "../../assets/Cost-illustration.svg";
import ImgProvenRecord from "../../assets/Proven-record-illustration.svg";
import ImgCustomizedIllustration from "../../assets/Customized-illustration.svg";

const Oaktree = () => (
  <>
    <SEO
      title="Oaktree - Young Professionals"
      description="We are the first, one, and only all-around career capital partner. We provide you mentorship, technical coaching, certification-based development programs, expert connections, event-speaking opportunities, and other imaginable supports to boost up your career."
    />
    <div className="section-single-hero">
      <div className="wrapper w-clearfix">
        <div className="h1">
          Our job is to set you up for success and impact, at your every job, at every level, in any business.
        </div>
        <div className="bodytext">
          We are the first, one, and only all-around career capital partner. We provide you mentorship, technical
          coaching, certification-based development programs, expert connections, event-speaking opportunities, and
          other imaginable supports to boost up your career.
        </div>
        <div className="block-two-row block-two-row-cards">
          <img src={ImgOaktreeSchool} alt="" className="block-two-row-image" />
          <div className="h3 block-two-row-title">Now you can speed up your growth anytime</div>
          <div className="bodytext">
            We understand that it can take dozens of millions IDR and scarcity of your leader&#x27;s time to grow. That
            is why we are here, to support you speed up your growth regardless.
          </div>
          <div className="block-button">
            <Link to={ROUTES.SCHOOL_FOR_PROFESSIONAL} className="button-primary button-primary-full w-inline-block">
              <div>Explore our courses and coaching programs</div>
            </Link>
            <a
              href={`${ROUTES.SCHOOL_FOR_PROFESSIONAL}#oaktree-how-it-works`}
              className="hyperlink-tertiary w-inline-block"
            >
              <div className="hyperlink-text">Learn how it works</div>
              <img src={ImgArrowTertiary} alt="" className="arrow-icon" />
            </a>
          </div>
        </div>
        <div className="block-two-row block-two-row-cards">
          <img src={ImgOaktreeCareer} alt="" className="block-two-row-image" />
          <div className="h3 block-two-row-title">
            Career Sponsorship and Mentorship: The two powerful career accelerators
          </div>
          <div className="bodytext">
            Sometimes, accelerating your career in this world full of limitation and competition requires support from
            the outside, including those who started earlier than you
          </div>
          <div className="block-button">
            <Link to={ROUTES.CAREER_CAPITAL} className="button-primary button-primary-full w-inline-block">
              <div>Explore our probono programs</div>
            </Link>
            <a href={`${ROUTES.CAREER_CAPITAL}#oaktree-how-find-mentor`} className="hyperlink-tertiary w-inline-block">
              <div className="hyperlink-text">Learn how it works</div>
              <img src={ImgArrowTertiary} alt="" className="arrow-icon" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div id="oaktree-personas" className="section-personas">
      <div className="wrapper w-clearfix">
        <div className="h2 h2-section-personas">How are we different from others?</div>
        <div className="bodytext bodytext-section-personas">
          We want to be your teacher-next-door: someone who is technically proven, yet very
          <br />
          reachable, and even, can offer you an affordable customized skill development program.
        </div>
        <div className="block-three-row">
          <img src={ImgCostIllustration} alt="" className="user-personas-student" />
          <div className="h4 h4-user-personas-text">Cost leadership</div>
          <div className="bodytext">
            We understand that you may spend more in order to survive in the city while supporting your family. So, to
            keep you learning while still successfully managing your personal need, we have found ways to provide you
            80-90% lower price than the market.
          </div>
        </div>
        <div className="block-three-row">
          <img src={ImgProvenRecord} alt="" className="user-personas-student" />
          <div className="h4 h4-user-personas-text">Proven-record coaches</div>
          <div className="bodytext">
            Our team consists of ex-consultants, unicorn players, ex-teachers, multinational experts who can, not only
            to coach you technically, but also to energize you mentally.
          </div>
        </div>
        <div className="block-three-row">
          <img src={ImgCustomizedIllustration} alt="" className="user-personas-student" />
          <div className="h4 h4-user-personas-text">Customized coaching</div>
          <div className="bodytext">
            In this VUCA world, we understand that there is a need for customization to make your learning practically
            useful. That is why, we deliver a close coaching-mentoring teaching style that can help you apply the
            learned skill directly to your work.
          </div>
        </div>
      </div>
    </div>
    <GeneralEmail />
    <PreFooter />
  </>
);

export default Oaktree;
