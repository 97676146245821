import React from "react";
import { Link } from "react-router-dom";

import SEO from "../Seo";
import { CollaborationEmail } from "../EmailForm";
import { PreFooter } from "../Footer";

import * as ROUTES from "../../constants/routes";

import ImgArrowTertiary from "../../assets/Arrow-Tertiary.svg";
import ImgOakwoodUniversityIllustration from "../../assets/Oakwood-university-illustration.svg";
import ImgContentAdaptation from "../../assets/Content-adaptation-illustration.svg";
import ImgPracticumContent from "../../assets/Practicum-content-illustration.svg";
import ImgUpcomingGrads from "../../assets/Upcoming-grads-program-illustration.svg";
import ImgHRSystem from "../../assets/HR-System.svg";

const University = () => (
  <>
    <SEO
      title="Oakwood - University"
      description="There are ways to make your graduates can easily be absorbed by top companies"
    />
    <div className="section-hero">
      <div className="wrapper w-clearfix">
        <div className="block-heroimage responsive-image">
          <img src={ImgOakwoodUniversityIllustration} alt="" />
        </div>
        <div className="block-herotext">
          <div className="h1">There are ways to make your graduates can easily be absorbed by top companies</div>
          <div className="bodytext">
            It is the Teaching Factory (TEFA) model that inspired us. We can bring professionals and organizations to
            help adapt your practicum or other teaching activities to the needs of the working world.
          </div>
          <div className="block-button">
            <a href="#oakwood-apply-university" className="button-primary w-inline-block">
              <div>Collaborate with us</div>
            </a>
            <a
              href="#oakwood-support-university"
              className="hyperlink-tertiary hyperlink-tertiary-with-badge w-inline-block"
            >
              <div className="hyperlink-text">Learn how it  works</div>
              <img src={ImgArrowTertiary} alt="" className="arrow-icon" />
            </a>
          </div>
        </div>
        <div className="block-heroimage">
          <img src={ImgOakwoodUniversityIllustration} alt="" />
        </div>
      </div>
    </div>
    <div id="oakwood-support-university" className="section-improve-university">
      <div className="wrapper">
        <div className="h2 h2-section-personas">How we can support your university</div>
        <div className="bodytext bodytext-section-personas">
          From adapting the syllabus or practicum content until setting up the university&#x27;s HR system, we can help
          your university nurture the youth to succeed in the working world.
        </div>
        <div className="block-line w-clearfix">
          <div className="block-two-row block-two-row-oakwood">
            <img src={ImgContentAdaptation} alt="" className="block-two-row-image" />
            <div className="h3 block-two-row-title">Syllabus content adaptation</div>
            <div className="bodytext">
              With the wide range of expert professionals and business organizations, we can help assist the adaptation
              of your existing syllabus to the working world.
            </div>
          </div>
          <div className="block-two-row block-two-row-oakwood">
            <img src={ImgPracticumContent} alt="" className="block-two-row-image" />
            <div className="h3 block-two-row-title">Practicum content adaptation</div>
            <div className="bodytext">
              We can help assist the development of your practicum with Competency-based Training (CBT) approach, based
              on the working world, like an Enterprise-based Training (EBT)
            </div>
          </div>
        </div>
        <div className="block-line w-clearfix">
          <div className="block-two-row block-two-row-oakwood">
            <img src={ImgUpcomingGrads} alt="" className="block-two-row-image" />
            <div className="h3 block-two-row-title">Bridging program for your upcoming graduates</div>
            <div className="bodytext">
              Taking the best practice from Germany, we can also collaborate to &quot;link and match&quot; your upcoming
              graduates directly to the needed competencies of the working world.
            </div>
            <Link to={ROUTES.OAKNUT} className="hyperlink-tertiary w-inline-block">
              <div className="hyperlink-text">Read more about our Bridging Program (Oaknut)</div>
              <img src={ImgArrowTertiary} alt="" className="arrow-icon" />
            </Link>
          </div>
          <div className="block-two-row block-two-row-oakwood">
            <img src={ImgHRSystem} alt="" className="block-two-row-image" />
            <div className="h3 block-two-row-title">
              HR system transformation
              <br />
              &zwj;
            </div>
            <div className="bodytext">
              If your university needs to modify your teachers&#x27; and staff arrangement such as incentives, tenure,
              promotion criteria in order to cultivate a more amazing spirit, our team that consists of ex-consultants
              and multinational expert is here to support.
            </div>
          </div>
        </div>
      </div>
    </div>
    <CollaborationEmail />
    <PreFooter />
  </>
);

export default University;
