import React, { useState } from "react";
import axios from "axios";

import ImgWhatsapp from "../../assets/icon-whatsapp.svg";

const UniversityEmail = () => {
  const [university, setUniversity] = useState("");
  const [city, setCity] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [btnText, setBtnText] = useState("Submit your university");
  const [failStyle, setFailStyle] = useState({ display: "none" });
  const [doneStyle, setDoneStyle] = useState({ display: "none", marginTop: 10 });

  const clearForm = () => {
    setUniversity("");
    setCity("");
    setBtnDisabled(false);
    setBtnText("Submit your university");
  };

  const onSubmit = event => {
    setFailStyle({ display: "none" });
    setDoneStyle({ display: "none", marginTop: 10 });
    setBtnText("Please wait....");
    setBtnDisabled(true);

    const MailAPI = `${process.env.REACT_APP_API_ENDPOINT}/api/inbox`;
    const axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const formData = new FormData();
    formData.append("University", university);
    formData.append("City", city);
    formData.append("Mail-From", process.env.REACT_APP_WEBSITE_URL);
    formData.append("Mail-Form", "Suggest University Form");
    formData.append("Mail-Category", "suggest-university-form");
    formData.append("Mail-Subject", "University Form Submitted");
    formData.append("Mail-Title", `${university} - ${city}`);
    formData.append("Mail-Excerpt", city);
    formData.append("Mail-Content", university);

    axios
      .post(MailAPI, formData, axiosConfig)
      .then(res => {
        clearForm();
        if (res.data.code === 200) {
          setDoneStyle({ display: "block", marginTop: 10 });
        } else {
          setFailStyle({ display: "block" });
        }
      })
      .catch(err => {
        setFailStyle({ display: "block" });
        console.log(err);
      });

    event.preventDefault();
  };

  return (
    <div id="oaknut-suggest-city" className="section-suggest-city">
      <div className="wrapper">
        <div className="h2 h2-section-personas">Your university or city is not in our list?</div>
        <div className="bodytext bodytext-section-personas">
          No need to worry, you can fill the form below. Our team will follow up the interest.
          <br />
          {"Make sure you have your community and/or university excited for us, too! "}
        </div>
        <div className="block-contact-suggest-university">
          <div className="block-contact-form-university w-form">
            <form
              id="email-form"
              name="email-form"
              data-name="Email Form"
              className="contact-form-university w-clearfix"
              action="submitform"
              onSubmit={onSubmit}
            >
              <div className="block-form-two-row block-form-two-row-university">
                <label htmlFor="University" className="form-label">
                  University
                </label>
                <input
                  type="text"
                  className="form-text-field w-input"
                  maxLength="256"
                  name="university"
                  placeholder="Type your university"
                  id="university"
                  value={university}
                  onChange={event => setUniversity(event.target.value)}
                  required
                />
              </div>
              <div className="block-form-two-row block-form-two-row-university form-university-child">
                <label htmlFor="City" className="form-label">
                  City
                </label>
                <input
                  type="text"
                  className="form-text-field w-input"
                  maxLength="256"
                  name="city"
                  placeholder="Your university is located"
                  id="city"
                  value={city}
                  onChange={event => setCity(event.target.value)}
                  required
                />
              </div>
              <input
                type="submit"
                value={btnText}
                className="button-primary button-primary-university w-button"
                disabled={btnDisabled}
              />
            </form>
            <div className="w-form-done" style={doneStyle}>
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div className="w-form-fail" style={failStyle}>
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
          <div className="block-contact-whatsapp-university">
            <div className="university-partnership">Direct reach us for university partnership</div>
            <a
              href="https://wa.me/6281221221500?text=Hi%2C%20aku%20mau%20tanya%2F%20request%2F%20book%20call%20tentang...."
              target="_blank"
              rel="noopener noreferrer"
              className="nav-link w-inline-block"
            >
              <img src={ImgWhatsapp} alt="" className="ico-whatsapp" />
              <div className="nav-link-text">
                <strong>+6281221221500</strong>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UniversityEmail;
