import React, { useState } from "react";
import axios from "axios";

const NeedSpeakerEmail = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [university, setUniversity] = useState("");
  const [eventPosition, setEventPosition] = useState("");
  const [aboutEvent, setAboutEvent] = useState("");
  const [topic, setTopic] = useState("");
  const [profileRequirement, setProfileRequirement] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [btnText, setBtnText] = useState("Submit");
  const [failStyle, setFailStyle] = useState({ display: "none" });
  const [doneStyle, setDoneStyle] = useState({ display: "none", marginTop: 10 });

  const clearForm = () => {
    setFullName("");
    setEmail("");
    setPhone("");
    setUniversity("");
    setEventPosition("");
    setAboutEvent("");
    setTopic("");
    setProfileRequirement("");
    setBtnDisabled(false);
    setBtnText("Submit");
  };

  const onSubmit = event => {
    setFailStyle({ display: "none" });
    setDoneStyle({ display: "none", marginTop: 10 });
    setBtnText("Please wait....");
    setBtnDisabled(true);

    const MailAPI = `${process.env.REACT_APP_API_ENDPOINT}/api/inbox`;
    const axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const formData = new FormData();
    formData.append("Full-name", fullName);
    formData.append("Email-address", email);
    formData.append("Phone-/-Whatsapp", phone);
    formData.append("Your-University,-Faculty,-and-Major", university);
    formData.append("Your-Position-in-The-Event", eventPosition);
    formData.append(
      "Tell-me-about-the-event:-the-event-owner-(organization),-the-theme,-the-date,-and-time",
      aboutEvent
    );
    formData.append("What-is-the-topic-that-you-want-the-speaker-to-speak-about?", topic);
    formData.append(
      "Do-you-have-any-specific-profile-requirement-to-be-the-potential-speaker?-when-do-you-need-this-by?",
      profileRequirement
    );
    formData.append("Mail-From", process.env.REACT_APP_WEBSITE_URL);
    formData.append("Mail-Reply", email);
    formData.append("Mail-Form", "Need Speaker Form");
    formData.append("Mail-Category", "need-speaker-form");
    formData.append("Mail-Subject", "Need Speaker Form Submitted");
    formData.append("Mail-Title", `${fullName} - ${university}`);
    formData.append("Mail-Excerpt", eventPosition);
    formData.append("Mail-Content", aboutEvent);

    axios
      .post(MailAPI, formData, axiosConfig)
      .then(res => {
        clearForm();
        if (res.data.code === 200) {
          setDoneStyle({ display: "block", marginTop: 10 });
        } else {
          setFailStyle({ display: "block" });
        }
      })
      .catch(err => {
        setFailStyle({ display: "block" });
        console.log(err);
      });

    event.preventDefault();
  };

  return (
    <div className="block-oakwood-hr-contact-form hr-sponsor-form block-oaktree-mentor-form w-form">
      <form
        id="wf-form-Need-speaker"
        name="wf-form-Need-speaker"
        data-name="Need-speaker"
        action="submitform"
        onSubmit={onSubmit}
      >
        <label htmlFor="fullName" className="form-label">
          Full name
        </label>
        <input
          type="text"
          className="form-text-field w-input"
          maxLength="256"
          name="fullName"
          placeholder="Type your full name"
          id="fullName"
          value={fullName}
          onChange={event => setFullName(event.target.value)}
          required
        />
        <div className="block-form-two-row-oaknut">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            className="form-text-field w-input"
            maxLength="256"
            name="email"
            placeholder="Type your email"
            id="email"
            value={email}
            onChange={event => setEmail(event.target.value)}
            required
          />
        </div>
        <div className="block-form-two-row-oaknut block-form-two-row-oaknut-children">
          <label htmlFor="phone" className="form-label">
            Phone / Whatsapp
          </label>
          <input
            type="text"
            className="form-text-field w-input"
            maxLength="256"
            name="phone"
            placeholder="Type active whatsapp or phone number"
            id="phone"
            value={phone}
            onChange={event => setPhone(event.target.value)}
            required
          />
        </div>
        <div className="block-form-two-row-oaknut">
          <label htmlFor="university" className="form-label">
            Your University, Faculty, and Major
          </label>
          <input
            type="text"
            className="form-text-field w-input"
            maxLength="256"
            name="university"
            placeholder="Type your University, Faculty, and Major"
            id="university"
            value={university}
            onChange={event => setUniversity(event.target.value)}
            required
          />
        </div>
        <div className="block-form-two-row-oaknut block-form-two-row-oaknut-children">
          <label htmlFor="eventPosition" className="form-label">
            Your Position in The Event
          </label>
          <input
            type="text"
            className="form-text-field w-input"
            maxLength="256"
            name="eventPosition"
            placeholder="Type your event position"
            id="eventPosition"
            value={eventPosition}
            onChange={event => setEventPosition(event.target.value)}
            required
          />
        </div>
        <label htmlFor="aboutEvent" className="form-label">
          Tell me about the event: the event owner (organization), the theme, the date, and time
        </label>
        <textarea
          placeholder="Tell us the details"
          maxLength="5000"
          id="aboutEvent"
          name="aboutEvent"
          className="form-text-area w-input"
          value={aboutEvent}
          onChange={event => setAboutEvent(event.target.value)}
          required
        />
        <label htmlFor="topic" className="form-label">
          What is the topic that you want the speaker to speak about?
        </label>
        <textarea
          placeholder="Tell us the topic"
          maxLength="5000"
          id="topic"
          name="topic"
          className="form-text-area w-input"
          value={topic}
          onChange={event => setTopic(event.target.value)}
          required
        />
        <label htmlFor="profileRequirement" className="form-label">
          Do you have any specific profile requirement to be the potential speaker? when do you need this by?
        </label>
        <textarea
          placeholder="Tell us more specific"
          maxLength="5000"
          id="profileRequirement"
          name="profileRequirement"
          className="form-text-area w-input"
          value={profileRequirement}
          onChange={event => setProfileRequirement(event.target.value)}
          required
        />
        <input type="submit" value={btnText} className="button-primary w-button" disabled={btnDisabled} />
      </form>
      <div className="w-form-done" style={doneStyle}>
        <div>Thank you! Your submission has been received!</div>
      </div>
      <div className="w-form-fail" style={failStyle}>
        <div>Oops! Something went wrong while submitting the form.</div>
      </div>
    </div>
  );
};

export default NeedSpeakerEmail;
