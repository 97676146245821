import React, { useState } from "react";
import axios from "axios";

const EnrollmentEmail = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [programTitle, setProgramTitle] = useState("");
  const [phone, setPhone] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [btnText, setBtnText] = useState("Submit");
  const [failStyle, setFailStyle] = useState({ display: "none" });
  const [doneStyle, setDoneStyle] = useState({ display: "none", marginTop: 10 });

  const clearForm = () => {
    setFullName("");
    setEmail("");
    setProgramTitle("");
    setPhone("");
    setBtnDisabled(false);
    setBtnText("Submit");
  };

  const onSubmit = event => {
    setFailStyle({ display: "none" });
    setDoneStyle({ display: "none", marginTop: 10 });
    setBtnText("Please wait....");
    setBtnDisabled(true);

    const MailAPI = `${process.env.REACT_APP_API_ENDPOINT}/api/inbox`;
    const axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const formData = new FormData();
    formData.append("Full-name", fullName);
    formData.append("Email-address", email);
    formData.append("Program-title", programTitle);
    formData.append("Phone-number", phone);
    formData.append("Mail-From", process.env.REACT_APP_WEBSITE_URL);
    formData.append("Mail-Reply", email);
    formData.append("Mail-Form", "Enrollment Form");
    formData.append("Mail-Category", "enrollment-form");
    formData.append("Mail-Subject", "Enrollment Form Submitted");
    formData.append("Mail-Title", `${fullName} - ${email}`);
    formData.append("Mail-Excerpt", phone);
    formData.append("Mail-Content", programTitle);

    axios
      .post(MailAPI, formData, axiosConfig)
      .then(res => {
        clearForm();
        if (res.data.code === 200) {
          setDoneStyle({ display: "block", marginTop: 10 });
        } else {
          setFailStyle({ display: "block" });
        }
      })
      .catch(err => {
        setFailStyle({ display: "block" });
        console.log(err);
      });

    event.preventDefault();
  };

  return (
    <div id="enroll-course" className="section-enroll-course">
      <div className="wrapper">
        <div className="block-oakwood-hr-contact-form hr-sponsor-form block-suggest-course-form w-form">
          <form
            id="wf-form-Be-mentor"
            name="wf-form-Be-mentor"
            data-name="Be-mentor"
            action="submitform"
            onSubmit={onSubmit}
          >
            <div className="h2 h2-course-enroll">Enrollment Form</div>
            <div className="block-form-two-row-oaknut block-form-suggest-course">
              <label htmlFor="fullName" className="form-label">
                Full name
              </label>
              <input
                type="text"
                className="form-text-field w-input"
                maxLength="256"
                name="fullName"
                placeholder="Type your full name"
                id="fullName"
                value={fullName}
                onChange={event => setFullName(event.target.value)}
                required
              />
            </div>
            <div className="block-form-two-row-oaknut block-form-two-row-oaknut-children block-form-suggest-course">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-text-field w-input"
                maxLength="256"
                name="email"
                placeholder="Type your Email"
                id="email"
                value={email}
                onChange={event => setEmail(event.target.value)}
                required
              />
            </div>
            <div className="block-form-two-row-oaknut block-form-suggest-course">
              <label htmlFor="programTitle" className="form-label">
                Program title
              </label>
              <input
                type="text"
                className="form-text-field w-input"
                maxLength="256"
                name="programTitle"
                placeholder="Type your program title"
                id="programTitle"
                value={programTitle}
                onChange={event => setProgramTitle(event.target.value)}
                required
              />
            </div>
            <div className="block-form-two-row-oaknut block-form-two-row-oaknut-children block-form-suggest-course">
              <label htmlFor="phone" className="form-label">
                Phone number
              </label>
              <input
                type="text"
                className="form-text-field w-input"
                maxLength="256"
                name="phone"
                placeholder="Type your phone number"
                id="phone"
                value={phone}
                onChange={event => setPhone(event.target.value)}
                required
              />
            </div>
            <input type="submit" value={btnText} className="button-primary w-button" disabled={btnDisabled} />
          </form>
          <div className="w-form-done" style={doneStyle}>
            <div>Thank you! Your submission has been received!</div>
          </div>
          <div className="w-form-fail" style={failStyle}>
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnrollmentEmail;
