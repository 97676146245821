import React, { useState } from "react";
import axios from "axios";

import ImgWhatsapp from "../../assets/icon-whatsapp.svg";

const CollaborationEmail = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [faculty, setFaculty] = useState("");
  const [position, setPosition] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [btnText, setBtnText] = useState("Book a call or meeting");
  const [failStyle, setFailStyle] = useState({ display: "none" });
  const [doneStyle, setDoneStyle] = useState({ display: "none", marginTop: 10 });

  const clearForm = () => {
    setFullName("");
    setEmail("");
    setPhone("");
    setFaculty("");
    setPosition("");
    setBtnDisabled(false);
    setBtnText("Book a call or meeting");
  };

  const onSubmit = event => {
    setFailStyle({ display: "none" });
    setDoneStyle({ display: "none", marginTop: 10 });
    setBtnText("Please wait....");
    setBtnDisabled(true);

    const MailAPI = `${process.env.REACT_APP_API_ENDPOINT}/api/inbox`;
    const axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const formData = new FormData();
    formData.append("Full-name", fullName);
    formData.append("Email-address", email);
    formData.append("Phone-number", phone);
    formData.append("Faculty", faculty);
    formData.append("Your-position-in-the-mentioned-university", position);
    formData.append("Mail-From", process.env.REACT_APP_WEBSITE_URL);
    formData.append("Mail-Reply", email);
    formData.append("Mail-Form", "Oakwood University Form");
    formData.append("Mail-Category", "oakwood-university-form");
    formData.append("Mail-Subject", "Oakwood University Form Submitted");
    formData.append("Mail-Title", `${fullName} - ${email} [${phone}]`);
    formData.append("Mail-Excerpt", faculty);
    formData.append("Mail-Content", position);

    axios
      .post(MailAPI, formData, axiosConfig)
      .then(res => {
        clearForm();
        if (res.data.code === 200) {
          setDoneStyle({ display: "block", marginTop: 10 });
        } else {
          setFailStyle({ display: "block" });
        }
      })
      .catch(err => {
        setFailStyle({ display: "block" });
        console.log(err);
      });

    event.preventDefault();
  };

  return (
    <div id="oakwood-apply-university" className="section-oaknut-contact">
      <div className="wrapper w-clearfix">
        <div className="block-oaknut-contact-description">
          <div className="h3 h3-oaknut-contact-description">Collaborate with us in 3 easy steps</div>
          <div className="block-contact-form-description w-clearfix">
            <div className="block-contact-indicator" />
            <div className="block-contact-description">
              <div className="block-contact-title">Book a call or a meeting</div>
              <div>
                One of our best partners will contact you to set up either a meeting or a call to discuss the current
                and desired state of your university.
              </div>
            </div>
          </div>
          <div className="block-contact-form-description w-clearfix">
            <div className="block-contact-indicator block-content-indicator-secondary" />
            <div className="block-contact-description">
              <div className="block-contact-title">Kick off</div>
              <div>
                If your university and our partner come to an agreed point on the scope and price, signing an agreement
                and kicking off the project through a meeting are required to start the project.
              </div>
            </div>
          </div>
          <div className="block-contact-form-description w-clearfix">
            <div className="block-contact-indicator block-content-indicator-tertiary" />
            <div className="block-contact-description">
              <div className="block-contact-title">Complete and get coached</div>
              <div>
                Our team will assist your university to complete the project based on the defined scope. However, for
                any HR transformation project, we can also assist your team to acquire the skill through deep coaching.
              </div>
            </div>
          </div>
        </div>
        <div className="block-oaknut-contact">
          <div className="block-oaknut-contact-form w-form">
            <form id="email-form" name="email-form" data-name="Email Form" action="submitform" onSubmit={onSubmit}>
              <div className="block-form-two-row-oaknut block-form-oakwood-university">
                <label htmlFor="fullName" className="form-label">
                  Full name
                </label>
                <input
                  type="text"
                  className="form-text-field w-input"
                  maxLength="256"
                  name="fullName"
                  placeholder="Type your name"
                  id="fullName"
                  value={fullName}
                  onChange={event => setFullName(event.target.value)}
                  required
                />
              </div>
              <div className="block-form-two-row-oaknut block-form-two-row-oaknut-children block-form-oakwood-university">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-text-field w-input"
                  maxLength="256"
                  name="email"
                  placeholder="Type your email"
                  id="email"
                  value={email}
                  onChange={event => setEmail(event.target.value)}
                  required
                />
              </div>
              <div className="block-form-two-row-oaknut block-form-oakwood-university">
                <label htmlFor="phone" className="form-label">
                  Phone
                </label>
                <input
                  type="text"
                  className="form-text-field w-input"
                  maxLength="256"
                  name="phone"
                  placeholder="Valid phone number"
                  id="phone"
                  value={phone}
                  onChange={event => setPhone(event.target.value)}
                  required
                />
              </div>
              <div className="block-form-two-row-oaknut block-form-two-row-oaknut-children block-form-oakwood-university">
                <label htmlFor="faculty" className="form-label">
                  Faculty
                </label>
                <select
                  id="faculty"
                  name="faculty"
                  className="form-text-field w-select"
                  value={faculty}
                  onChange={event => setFaculty(event.target.value)}
                  required
                >
                  <option value="">Select one...</option>
                  <option value="Technology Information">Technology Information</option>
                  <option value="Social">Social</option>
                  <option value="Economy">Economy</option>
                </select>
              </div>
              <label htmlFor="position" className="form-label">
                Your position in the mentioned university
              </label>
              <input
                type="text"
                className="form-text-field w-input"
                maxLength="256"
                name="position"
                placeholder="Type your position"
                id="Position"
                value={position}
                onChange={event => setPosition(event.target.value)}
                required
              />
              <div className="block-button-form-university">
                <div className="badge-medium badge-button-form">
                  <div>Free</div>
                </div>
                <input
                  type="submit"
                  value={btnText}
                  className="button-primary button-tertiary button-form-full w-button"
                  disabled={btnDisabled}
                />
              </div>
              <div className="secondary-block-button-form-university">
                <div className="university-partnership hyperlink-oakwood-university">I prefer casual whatsapp chat</div>
                <a
                  href="https://wa.me/6281221221500?text=Hi%2C%20aku%20mau%20tanya%2F%20request%2F%20book%20call%20tentang...."
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-link w-inline-block"
                >
                  <img src={ImgWhatsapp} alt="" className="ico-whatsapp" />
                  <div className="nav-link-text">
                    <strong>+6281221221500</strong>
                  </div>
                </a>
              </div>
            </form>
            <div className="w-form-done" style={doneStyle}>
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div className="w-form-fail" style={failStyle}>
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollaborationEmail;
